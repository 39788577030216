import React, { Component } from "react";

import alertify from "alertifyjs";
import _ from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import Logo from "../../components/logo";
import { resendActivation } from "../../api/account";

export default class UserResendActivationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      hasError: false,
      isLoading: false,
    };
  }

  handleChange = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    const { email } = this.state;
    this.setState({
      isLoading: true,
    });
    resendActivation(
      {
        email,
      },
      (response) => {
        if (response.status >= 200 && response.status < 300) {
          alertify.success("Please check your email for instructions.");
          this.setState({ email: "" });
        } else if (response.status >= 400 && response.status < 500) {
          alertify.error(
            Object.entries(response.data)
              .map(([key, value]) => {
                return `${_.startCase(key).toUpperCase()} - ${value.join()}`;
              })
              .join()
          );
        } else {
          alertify.error(response.statusText);
        }
        this.setState({
          isLoading: false,
        });
      }
    );
    return false;
  };

  render() {
    const { email, isLoading } = this.state;
    return (
      <div className="login-container box-shadow--dark">
        <div className="login-header">
          <div className="d-inline-block login-logo">
            <Logo width="40" height="47" />
          </div>
          <div className="d-inline-block login-title">
            <span>CashMob</span>
            <span className="subtitle">P O R T A L</span>
          </div>
        </div>
        <div className="login-body">
          <form onSubmit={this.handleSubmit}>
            <input
              type="text"
              className="login-input-field"
              value={email}
              name="email"
              onChange={this.handleChange}
              placeholder="Email"
              disabled={isLoading}
              required
            />
            <br />
            <button
              type="submit"
              className="primary-color-btn login-submit-button"
              disabled={this.state.isLoading}
            >
              {this.state.isLoading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                "Resend activation email"
              )}
            </button>
          </form>
        </div>
      </div>
    );
  }
}
