import React, { Component } from "react";

import { observer, inject } from "mobx-react";
import { getChildType } from "mobx-state-tree";

import Details from "./../../../../components/cards/details";
import Form from "./../../../../components/cards/form";

import NotFoundPage from "../../../others/not-found-page";
import Table from "../../../../components/cards/table";
import { ClientAddresses } from "../../../../data/models/client-address";
import withAuthorization from "../../../../components/authorization";
import Loading from "../../../../components/loading";
import EditableDetails from "../../../../components/editable-details";
import { CashUnitConfiguration } from "../../../../data/models/cash-unit-configuration";

const clientAddressesStore = ClientAddresses.create({
  count: 0,
  results: [],
  next: null,
  previous: null,
});

@inject("appDataStore")
@inject("prePlannedTripsStore")
@inject("cashUnitConfigurationsStore")
@inject("cashUnitsStore")
@observer
@withAuthorization({
  allowedRoles: ["cash_services_admin", "cash_unit_admin"],
  permissions: ["view_cashunit"],
})
class CashUnitPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cashUnit: undefined,
      cashUnitConfiguration: undefined,
      prePlannedTrip: undefined,
      isEditMode: false,
    };
  }

  async componentDidMount() {
    const { cashUnitsStore, cashUnitConfigurationsStore } = this.props;
    const { cashUnitId } = this.props.match.params;
    const response = await cashUnitsStore.retrieve(cashUnitId);
    const cashUnit = getChildType(cashUnitsStore.results).create(response.data);
    this.setState({ cashUnit });

    const cashUnitConfigurationResponse =
      await cashUnitConfigurationsStore.retrieve(
        cashUnit.cashUnitConfiguration
      );
    const cashUnitConfiguration = CashUnitConfiguration.create(
      cashUnitConfigurationResponse.data
    );

    this.setState({ cashUnitConfiguration });
  }

  toggleEditMode = () => {
    const { isEditMode } = this.state;
    this.setState({ isEditMode: !isEditMode });
  };

  render() {
    const { cashUnitsStore, prePlannedTripsStore, appDataStore } = this.props;
    const { cashUnit, isEditMode, cashUnitConfiguration } = this.state;

    const { currentUser } = appDataStore;
    const { canChange, canDelete } = currentUser.getPermissions("cashunit");
    const {
      canChange: canChangeCashUnitConfiguration,
      canDelete: canDeleteCashUnitConfiguration,
    } = currentUser.getPermissions("cashunitconfiguration");

    if (cashUnit === undefined) {
      return <Loading />;
    } else if (cashUnit === null) {
      return <NotFoundPage />;
    }

    return (
      <div className="animated fadeIn">
        {/* Display objects related to this cash unit. */}
        <div className="d-flex flex-column" style={{ gap: "8px" }}>
          {cashUnit && (
            <div>
              <h4 className="p-3">Cash Unit</h4>
              {isEditMode ? (
                <Form
                  data={cashUnit}
                  headerTitle="EDIT CASH UNIT DETAILS"
                  onClose={this.toggleEditMode}
                  editable={canChange}
                  deletable={canDelete}
                />
              ) : (
                <Details
                  data={cashUnit}
                  isLoading={cashUnitsStore.isLoading}
                  headerTitle="CASH UNIT DETAILS"
                  onEdit={this.toggleEditMode}
                />
              )}
            </div>
          )}
          <div>
            <EditableDetails
              title="Cash Unit Configuration"
              instance={cashUnitConfiguration}
              canChange={canChangeCashUnitConfiguration}
              canDelete={canDeleteCashUnitConfiguration}
            />
          </div>
          {cashUnit && (
            <div>
              <h4 className="p-3">Client Addresses</h4>
              <Table
                {...this.props}
                store={clientAddressesStore}
                filter={{ cash_unit: cashUnit.id }}
                objectKeys={["name", "type", "groupName"]}
                linkToPath={"/config/client-addresses/"}
              />
            </div>
          )}

          {cashUnit && (
            <div>
              <h4 className="p-3">Pre-Planned Trips</h4>
              <Table
                {...this.props}
                store={prePlannedTripsStore}
                filter={{ cash_unit: cashUnit.id }}
                objectKeys={["combination", "distance"]}
                linkToPath={"/config/pre-planned-trips/"}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CashUnitPreview;
