import React from "react";
import Drawer from "./drawer";

class FormDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      disabled: false,
    };
  }

  render() {
    const { title, triggerButton, submitButton, children } = this.props;
    const { isOpen, disabled } = this.state;
    return (
      <>
        {React.cloneElement(triggerButton, {
          onClick: () => this.setState({ isOpen: !isOpen }),
          "data-testid": "show-create-form",
        })}
        {isOpen && (
          <Drawer
            title={title}
            onClose={() => this.setState({ isOpen: false })}
            renderFooter={
              <>
                <button
                  type="button"
                  data-testid="form-cancel-item"
                  className="secondary-color-btn"
                  disabled={disabled}
                  onClick={() => this.setState({ isOpen: false })}
                >
                  Close
                </button>
                {React.cloneElement(submitButton, {
                  disabled: disabled,
                  onClick: async () => {
                    this.setState({ disabled: true });
                    await submitButton.props.onClick();
                    this.setState({ disabled: false });
                  },
                })}
              </>
            }
          >
            {children}
          </Drawer>
        )}
      </>
    );
  }
}

export default FormDrawer;
