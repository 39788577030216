import { types } from "mobx-state-tree";

import { requestWithToken } from "../../api";

import { BaseModel, Pagination } from ".";

import { MasterCifByIdReference } from "./master-cif";

import { CreateModelMixin, RetrieveModelMixin } from "./mixins";

const CifIdFields = types
  .compose(
    CreateModelMixin,
    RetrieveModelMixin,
    types.model({
      value: types.string,
      masterCif: types.maybeNull(MasterCifByIdReference),
      masterCifValue: types.maybeNull(types.string),
    })
  )
  .views((self) => ({
    get display() {
      return self.value;
    },
  }))
  .volatile((self) => ({
    endpoint: "cif-ids",
    // readOnly: true,
    hiddenFields: ["masterCifValue"],
  }));

const CifId = types.compose(CifIdFields, BaseModel).actions((self) => ({
  setValue: (value) => {
    self.value = value;
  },
}));

const CifIds = types
  .compose(
    Pagination,
    types.model({
      results: types.array(CifId),
    })
  )
  .volatile((self) => ({
    endpoint: "cif-ids",
  }));

const CifIdByIdReference = types.reference(CifId, {
  async get(value) {
    const response = await requestWithToken.get(`cif-ids/${value}/`);
    let cifId = CifId.create({
      ...response.data,
    });
    return cifId;
  },
  set(value) {
    return value;
  },
});

export { CifIdFields, CifId, CifIds, CifIdByIdReference };
