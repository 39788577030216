import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";

import Dashboard from "./dashboard";
import Configuration from "./config";
import Users from "./users";

import TopNavbar from "../../components/navbar/top-navbar";
import SideNavbar from "../../components/navbar/side-navbar";
import FooterNavbar from "../../components/navbar/footer-navbar";
import { withMsal } from "@azure/msal-react";
import Loading from "../../components/loading";

@withMsal
@inject("appDataStore")
@observer
class MainView extends Component {
  async componentDidMount() {
    const { appDataStore } = this.props;
    await appDataStore.getCurrentUser();
  }

  render() {
    const { appDataStore } = this.props;
    return (
      <div className="d-flex flex-row">
        <SideNavbar />
        <div className="d-flex flex-column flex-fill">
          <TopNavbar />
          {appDataStore.currentUser ? (
            <div>
              <Switch>
                <Route path="/users" component={Users} />
                <Route path="/config" component={Configuration} />
                <Route exact path="/" component={Dashboard} />
                {/* <Route component={NotFoundPage} /> */}
                <Route>
                  <Redirect to="/" />
                </Route>
              </Switch>
            </div>
          ) : (
            <Loading />
          )}
          <FooterNavbar />
        </div>
      </div>
    );
  }
}

export default MainView;
