import { types } from "mobx-state-tree";

import { BaseModel } from ".";
import { TruckByIdReference } from "./truck";
import { CreateModelMixin, RetrieveModelMixin } from "./mixins";


const TruckGPSFields = types
  .compose(
    CreateModelMixin,
    RetrieveModelMixin,
    types.model({
      latitude: types.maybeNull(types.string),
      longitude: types.maybeNull(types.string),
      isOnline: types.boolean,
      truck: types.union(TruckByIdReference, types.integer, types.null),
    })
  )
  .volatile((self) => ({
    endpoint: "truck-gps",
  }));

const TruckGPS = types.compose(BaseModel, TruckGPSFields);


export {
  TruckGPS,
  TruckGPSFields,
}