import { types } from "mobx-state-tree";

import { BaseModel, Pagination, BaseFilters } from ".";

import { CreateModelMixin, RetrieveModelMixin, ActionMixin } from "./mixins";
import { DateTimeType, DateType } from "./datetime-type";
import { Truck, TruckByIdReference } from "./truck";
import {
  CashUnitPersonnel,
  CashUnitPersonnelByIdReference,
} from "./cash-unit-personnel";
import { TripSchedule } from "./trip-schedule";
import { TruckPersonnel, TruckPersonnelByIdReference } from "./truck-personnel";
import { CashUnit, CashUnitByIdReference } from "./cash-unit";
import { TruckGPS } from "./truck-gps";
import { requestWithToken } from "../../api";

const TripFilters = types.compose(
  ActionMixin,
  BaseFilters,
  types.model({
    date: types.maybeNull(DateType),
    cashUnit: types.maybeNull(CashUnitByIdReference),
  })
);

const TripFields = types
  .compose(
    CreateModelMixin,
    RetrieveModelMixin,
    types.model({
      cashUnit: types.union(
        CashUnitByIdReference,
        CashUnit,
        types.integer,
        types.null
      ),
      truck: types.union(TruckByIdReference, Truck, types.integer, types.null),
      teller: types.union(
        CashUnitPersonnelByIdReference,
        CashUnitPersonnel,
        types.integer,
        types.null
      ),
      driver: types.union(types.string, types.null),
      escort: types.union(
        TruckPersonnelByIdReference,
        TruckPersonnel,
        types.integer,
        types.null
      ),
      teamLeader: types.union(
        TruckPersonnelByIdReference,
        TruckPersonnel,
        types.integer,
        types.null
      ),
      date: types.maybeNull(types.string),
      departureTime: types.maybeNull(DateTimeType),
      arrivalTime: types.maybeNull(DateTimeType),
      tellerName: types.maybeNull(types.string),
      truckName: types.maybeNull(types.string),
      cashUnitIdentifier: types.maybeNull(types.string),
    })
  )
  .volatile((self) => ({
    endpoint: "trips",
    readOnlyFields: [
      "departureTime",
      "arrivalTime",
      "date",
      // "driver",
      // "cashUnit",
      // "teamLeader",
      // "escort",
    ],
    hiddenFields: ["driver", "tellerName", "truckName", "cashUnitIdentifier"],
    referenceFilterOptions: {
      truck: {
        available: true,
        isActive: true,
        cashUnit: self.toJSON().cashUnit,
      },
      teller: {
        available: true,
        isTeller: true,
        isActive: true,
        cashUnit: self.toJSON().cashUnit,
      },
    },
  }))
  .views((self) => ({
    get display() {
      return `${self.truckName} - ${self.tellerName}`;
    },
  }));

const DetailedTripFields = types
  .compose(
    CreateModelMixin,
    RetrieveModelMixin,
    TripFields,
    types.model({
      currentTripSchedule: types.union(types.integer, types.null),
      tripSchedules: types.array(types.late(() => TripSchedule)),
      truckGps: types.maybeNull(TruckGPS),
    })
  )
  .volatile((self) => ({
    endpoint: "trips",
  }));

const Trip = types.compose(TripFields, BaseModel);
const DetailedTrip = types.compose(DetailedTripFields, BaseModel);

const Trips = types
  .compose(
    Pagination,
    types.model({
      results: types.array(Trip),
    })
  )
  .volatile((self) => ({
    endpoint: "trips",
  }));

const TripByIdReference = types.reference(Trip, {
  async get(value) {
    const response = await requestWithToken.get(`trips/${value}/`);
    let obj = Trip.create({
      ...response.data,
    });
    return obj;
  },
  set(value) {
    return value;
  },
});

export {
  TripFields,
  Trip,
  Trips,
  DetailedTripFields,
  DetailedTrip,
  TripByIdReference,
  TripFilters,
};
