import React from "react";
import adiLogo from "../assets/images/logo-adi.png";

const LogoAdi = (props) => {
  return (
    <a href="https://aboitizdatainnovation.com/" className="text-muted p-3">
      <span>Powered by</span>
      <img
        src={adiLogo}
        alt="Aboitiz Data Innovation"
        className="rounded mx-auto d-block"
        width="96"
      />
    </a>
  );
};

export default LogoAdi;
