import { types } from "mobx-state-tree";
import moment from "moment-timezone";

const DateTimeType = types.custom({
  name: "DateTime",

  isTargetType(value) {
    return value instanceof Date;
  },
  getValidationMessage(value) {
    if (moment(value).isValid()) {
      return "";
    }
    return `'${value}' doesn't look like a DateTime`;
  },
  fromSnapshot(value) {
    return moment.utc(value).local();
  },
  toSnapshot(value) {
    return value.format();
  },
});

const TimeType = types.custom({
  name: "Time",

  isTargetType(value) {
    return value instanceof String;
  },
  getValidationMessage(value) {
    if (/[0-2]\d:[0-5]\d?/.test(value)) {
      return "";
    }
    return `'${value}' doesn't look like a time format`;
  },
  fromSnapshot(value) {
    return value;
  },
  toSnapshot(value) {
    return value;
  },
});

const DateType = types.custom({
  name: "Date",

  isTargetType(value) {
    return value instanceof String;
  },
  getValidationMessage(value) {
    if (/[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(value)) {
      return "";
    }
    return `'${value}' doesn't look like a Date format`;
  },
  fromSnapshot(value) {
    return value;
  },
  toSnapshot(value) {
    return value;
  },
});

export { DateTimeType, TimeType, DateType };
