// Frontend API root
import { setup } from "axios-cache-adapter";
import localforage from "localforage";
import memoryDriver from "localforage-memoryStorageDriver";

export const SERVER = process.env.REACT_APP_API_ENDPOINT; // from ENV variable
export const VERSION = process.env.REACT_APP_API_VERSION; // from ENV variable
export const WEBSOCKET = process.env.REACT_APP_WEBSOCKET_ENDPOINT;

export const ROOT = `${SERVER}/`;
export const API_ROOT = `${ROOT}api/${VERSION}/`;
console.log(API_ROOT);

const configure = (axiosOptions) => {
  // Register the custom `memoryDriver` to `localforage`
  (async () => await localforage.defineDriver(memoryDriver))();

  // Create `localforage` instance
  const forageStore = localforage.createInstance({
    // List of drivers used
    driver: [
      localforage.INDEXEDDB,
      localforage.LOCALSTORAGE,
      memoryDriver._driver,
    ],
    // Prefix all storage keys to prevent conflicts
    name: "cashmob-cache",
  });

  // Create `axios` instance with pre-configured `axios-cache-adapter` using a `localforage` store
  return setup({
    // `axios` options
    ...axiosOptions,

    // `axios-cache-adapter` options
    cache: {
      maxAge: 0,
      store: forageStore, // Pass `localforage` store to `axios-cache-adapter`
      debug: false,
    },
  });
};

export const request = configure({
  baseURL: API_ROOT,
  headers: {
    "Content-Type": "application/json",
  },
});

export const requestWithToken = configure({
  baseURL: API_ROOT,
  headers: {
    "Content-Type": "application/json",
  },
});

export const requestWithTokenNoBaseUrl = configure({
  headers: {
    "Content-Type": "application/json",
  },
});

const requestErrorHandler = (error) => {
  // if has response show the error
  console.log(JSON.stringify(error, null, 2));
  if (error.response) {
    if (error.response.status === 401) {
      localStorage.removeItem("CM_AUTH");
      localStorage.clear();
      setTimeout(() => {
        window.location.assign("/");
      }, 500);
    }
  }
  return Promise.reject(error);
};

const requestHandler = (config) => {
  config.headers.Authorization = localStorage.getItem("CM_AUTH");

  if (!config.url.includes("?") && config.url[config.url.length - 1] !== "/") {
    config.url += "/";
  }
  return config;
};

requestWithToken.interceptors.request.use((config) => {
  return requestHandler(config);
});

requestWithTokenNoBaseUrl.interceptors.request.use((config) => {
  return requestHandler(config);
});

requestWithToken.interceptors.response.use((response) => {
  return response;
}, requestErrorHandler);

requestWithTokenNoBaseUrl.interceptors.response.use(
  (response) => response,
  requestErrorHandler
);

export const WEBSOCKET_ROOT = `${WEBSOCKET}/ws/`;
export const WS_TRUCKS_URL = WEBSOCKET_ROOT + "trucks/";
export const WS_TRIPS_URL = WEBSOCKET_ROOT + "trips/";
export const WS_CLIENT_ADDRESSES_URL = WEBSOCKET_ROOT + "client-addresses/";
export const WS_TRIP_SCHEDULES_URL = WEBSOCKET_ROOT + "trip-schedules/";
