import React, { Component } from "react";

import { observer, inject } from "mobx-react";
import { getChildType } from "mobx-state-tree";

import Details from "./../../../../components/cards/details";
import Form from "./../../../../components/cards/form";
import NotFoundPage from "../../../others/not-found-page";

import Loading from "../../../../components/loading";
import withAuthorization from "../../../../components/authorization";

@inject("appDataStore")
@inject("cashUnitPersonnelsStore")
@observer
@withAuthorization({
  allowedRoles: ["cash_services_admin", "cash_unit_admin"],
  permissions: ["view_cashunitpersonnel"],
})
class CashUnitPersonnelPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cashUnitPersonnel: undefined,
      isEditMode: false,
    };
  }
  async componentDidMount() {
    const { cashUnitPersonnelsStore } = this.props;
    const { cashUnitPersonnelId } = this.props.match.params;
    const response = await cashUnitPersonnelsStore.retrieve(
      cashUnitPersonnelId
    );
    if (response) {
      const cashUnitPersonnel = getChildType(
        cashUnitPersonnelsStore.results
      ).create(response.data);
      this.setState({ cashUnitPersonnel });
    } else {
      this.setState({ cashUnitPersonnel: null });
    }
  }

  toggleEditMode = () => {
    const { isEditMode } = this.state;
    this.setState({ isEditMode: !isEditMode });
  };

  render() {
    const { cashUnitPersonnelsStore, appDataStore } = this.props;
    const { cashUnitPersonnel, isEditMode } = this.state;

    const { currentUser } = appDataStore;
    const { canChange, canDelete } =
      currentUser.getPermissions("cashunitpersonnel");

    if (cashUnitPersonnel === null) {
      return <NotFoundPage />;
    } else if (cashUnitPersonnel === undefined) {
      return <Loading />;
    }

    return (
      <div className="animated fadeIn">
        <div className="row">
          <div className="col-8">
            <div className="card-container w-100">
              {!isEditMode && (
                <Details
                  data={cashUnitPersonnel}
                  isLoading={cashUnitPersonnelsStore.isLoading}
                  headerTitle="CASH UNIT PERSONNEL DETAILS"
                  onEdit={this.toggleEditMode}
                  editable={canChange}
                  deletable={canDelete}
                />
              )}
              {isEditMode && (
                <Form
                  data={cashUnitPersonnel}
                  headerTitle="EDIT CASH UNIT PERSONNEL DETAILS"
                  onClose={this.toggleEditMode}
                />
              )}
            </div>
          </div>
        </div>
        <div className="row">
          {/* Display objects related to this cash unit. */}
        </div>
      </div>
    );
  }
}

export default CashUnitPersonnelPreview;
