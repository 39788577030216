import React, { Component } from "react";
import Table from "../../../../components/cards/table";
import { observer, inject } from "mobx-react";
import withAuthorization from "../../../../components/authorization";
import FormDrawer from "../../../../components/form-drawer";
import Form from "../../../../components/cards/form";
import {
  ScheduleFields,
  ScheduleFilters,
} from "../../../../data/models/schedule";

const ROUTE_URL = "/config/schedules/";

@inject("appDataStore")
@inject("schedulesStore")
@observer
@withAuthorization({
  allowedRoles: ["cash_services_admin", "cash_unit_admin"],
  permissions: ["view_schedule"],
})
class Schedules extends Component {
  showNewlyAdded = (item) => {
    window.location.assign(`${ROUTE_URL}${item.id}`);
  };

  render() {
    const { schedulesStore, appDataStore } = this.props;
    const { currentUser } = appDataStore;
    const { canAdd, canView } = currentUser.getPermissions("schedule");

    return (
      <div className="animated fadeIn">
        <div className="d-flex justify-content-between">
          <div className="config-breadcrumbs ">
            <span className="active">Schedules</span>
          </div>
          {canAdd && (
            <FormDrawer
              title="Add Schedule"
              triggerButton={
                <button
                  className="primary-color-btn"
                  data-testid="form-submit-item"
                >
                  Add Schedule
                </button>
              }
              submitButton={
                <button
                  type="button"
                  className="primary-color-btn"
                  data-testid="form-submit-item"
                  onClick={async () => await this.submitHandler()}
                >
                  Submit
                </button>
              }
            >
              <Form
                mode="form"
                data={ScheduleFields.create({
                  dayOfWeek: "",
                  servicingType: "",
                  verificationProcess: "",
                  clientAddress: null,
                  sameDayRequestOnly: false,
                  // preferredTime: null,
                  groupName: "",
                })}
                onSubmit={this.showNewlyAdded}
                submitHandler={(submitHandler) => {
                  this.submitHandler = submitHandler;
                }}
              />
            </FormDrawer>
          )}
        </div>

        <Table
          {...this.props}
          store={schedulesStore}
          filterFields={ScheduleFilters.create({
            isActive: "",
            verificationProcess: "",
            servicingType: "",
            dayOfWeek: "",
            group: null,
          })}
          objectKeys={[
            "clientAddressName",
            "day",
            "groupName",
            "servicingType",
            "verificationProcess",
          ]}
          linkToPath={ROUTE_URL}
          canView={canView}
        />
      </div>
    );
  }
}

export default Schedules;
