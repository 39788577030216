import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Loading = (props) => {
  return (
    <div className="text-center m-5 p-5">
      <FontAwesomeIcon
        {...props}
        className="default-loading-icon"
        icon={faSpinner}
        spin
        pulse
      />
    </div>
  );
};

export default Loading;
