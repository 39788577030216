import { types } from "mobx-state-tree";

import { requestWithToken } from "../../api";

import { BaseModel, Pagination } from ".";

import { CreateModelMixin, RetrieveModelMixin } from "./mixins";
import { TripScheduleByIdReference } from "./trip-schedule";

const TransactionFields = types
  .compose(
    CreateModelMixin,
    RetrieveModelMixin,
    types.model({
      currency: types.string,
      cashIn: types.string,
      cashOut: types.string,
      changeFund: types.string,
      tripSchedule: TripScheduleByIdReference,
    })
  )
  .actions((self) => ({
    setValue: (value) => {
      self.value = value;
    },
  }))
  .volatile((self) => ({
    endpoint: "transactions",
    readOnly: true,
  }));

const Transaction = types.compose(TransactionFields, BaseModel);

const Transactions = types
  .compose(
    Pagination,
    types.model({
      results: types.array(Transaction),
    })
  )
  .volatile((self) => ({
    endpoint: "transactions",
  }));

const TransactionByIdReference = types.reference(Transaction, {
  async get(value) {
    const response = await requestWithToken.get(`transactions/${value}/`);
    let obj = Transaction.create({
      ...response.data,
    });
    return obj;
  },
  set(value) {
    return value;
  },
});

export {
  TransactionFields,
  Transaction,
  Transactions,
  TransactionByIdReference,
};
