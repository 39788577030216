import React from "react";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import Loading from "../loading";
import { PAGE_SIZE } from "../../data/constants";
import DisplayValue from "../display-value";
import Form from "./form";
import { getModelDetails } from "../../utils";
import { getChildType } from "mobx-state-tree";
import { BaseFilters } from "../../data/models";
import { reaction } from "mobx";

@observer
class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilters: false,
      filterFields:
        this.props.filterFields ||
        BaseFilters.create({ isActive: "", search: "" }),
    };
  }

  fetchData = async () => {
    this._fetchData();
  };

  async componentDidMount() {
    const { filterFields } = this.state;
    const { store, filter } = this.props;
    this._fetchData = _.debounce(async () => {
      await store.list({
        ...filterFields,
        ...filter,
      });
    }, 500);

    await this.fetchData();
    reaction(
      () => ({ ...filterFields }),
      async (filterFields) => {
        await this.fetchData();
      }
    );
  }

  toggleCreateForm = () => {
    this.setState({
      showCreateForm: !this.state.showCreateForm,
    });
  };

  showNewlyAdded = (item) => {
    const { linkToPath } = this.props;
    window.location.assign(`${linkToPath}${item.id}`);
  };

  render() {
    const { showFilters, filterFields } = this.state;
    const { store, objectKeys, linkToPath, addButton, canAdd } = this.props;
    const propsToDisplay = getChildType(store.results).propertyNames.filter(
      (field) => objectKeys.includes(field)
    );
    return (
      <div className="card-container">
        <div className="config-card-list box-shadow--light">
          <div className="config-card__header">
            <div className="header__button">
              {filterFields && (
                <button
                  type="button"
                  data-testid="filter-fields"
                  className="btn btn-link primary-color-link"
                  onClick={() => this.setState({ showFilters: !showFilters })}
                >
                  {showFilters ? "Hide" : "Show"} filters
                </button>
              )}
              {canAdd && React.cloneElement(addButton)}
            </div>
            <div>
              <div className="d-block p-3">
                {showFilters && <Form data={filterFields} mode="form" />}
              </div>
            </div>
          </div>
          <div className="min-vh-50 config-card__body text-nowrap table-responsive">
            <table className="table table-light">
              <thead className="config-card__list-head">
                <tr className="config-card__list-head-item">
                  <th scope="col">NO.</th>
                  {propsToDisplay.map((field, index) => {
                    return (
                      <th scope="col" key={index}>
                        {_.startCase(field).toUpperCase()}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              {store.isLoading ? (
                <tbody className="config-card__list-body">
                  <tr>
                    <td className="text-center" colSpan={objectKeys.length + 1}>
                      <Loading />
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody className="config-card__list-body">
                  {store.results.map((item, index) => {
                    const fields = getModelDetails(item, objectKeys);
                    return (
                      <tr
                        className="config-card__list-body-item"
                        onClick={() =>
                          this.props.history.push(`${linkToPath}${item.id}`)
                        }
                        key={index}
                      >
                        <th scope="row">
                          {store.pageNum * PAGE_SIZE - PAGE_SIZE + (index + 1)}
                        </th>
                        {fields.map((field, index) => {
                          return (
                            <td key={index}>
                              <DisplayValue {...field} />
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              )}
              {!store.isLoading && store.results.length === 0 && (
                <tbody className="config-card__list-body">
                  <tr>
                    <td className="text-center" colSpan={objectKeys.length + 1}>
                      <span className="text-muted">No items found</span>
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>

          <div className="config-card__footer">
            <button
              type="button"
              className="primary-color-btn float-left"
              onClick={async () => await store.previousPage()}
              disabled={store.previous === null ? true : false}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <span className="page-indicator">
              PAGE {store.pageNum} OF {store.totalPageNum}
            </span>
            <button
              type="button"
              className="primary-color-btn float-right"
              onClick={async () => await store.nextPage()}
              disabled={store.next === null ? true : false}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Table;
