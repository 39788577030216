import { types } from "mobx-state-tree";

import { requestWithToken } from "../../api";

import { BaseModel, Pagination, BaseFilters } from ".";
import { CashUnit, CashUnitByIdReference } from "./cash-unit";
import { CifIdByIdReference } from "./cif-id";
import { CreateModelMixin, RetrieveModelMixin } from "./mixins";

import { AddressGeocode, AddressGeocodeByIdReference } from "./address-geocode";
import { GroupByIdReference } from "./group";

const ClientAddressType = types.enumeration("Enum", [
  "BRANCH",
  "ATM",
  "BSP",
  "STORE",
]);

const ClientAddressFilters = types.compose(
  BaseFilters,
  types.model({
    cashUnit: types.maybeNull(CashUnitByIdReference),
    type: types.union(ClientAddressType, types.literal("")),
    group: types.maybeNull(GroupByIdReference),
  })
);

const ClientAddressFields = types
  .compose(
    CreateModelMixin,
    RetrieveModelMixin,
    types.model({
      name: types.string,
      address: types.string,
      addressGeocode: types.union(
        AddressGeocodeByIdReference,
        AddressGeocode,
        types.integer,
        types.null,
        types.undefined
      ),
      accountNumber: types.string,
      cashUnit: types.union(
        CashUnitByIdReference,
        CashUnit,
        types.integer,
        types.null
      ),
      cashUnitIdentifier: types.maybeNull(types.string),
      type: types.union(ClientAddressType, types.literal("")),
      cifId: types.union(CifIdByIdReference, types.integer, types.null),
      groupName: types.maybeNull(types.string),
    })
  )
  .views((self) => ({
    get display() {
      return self.name;
    },
  }))
  .volatile((self) => ({
    endpoint: "client-addresses",
    readOnlyFields: ["groupName", "addressGeocode"],
    hiddenFields: ["cashUnitIdentifier"],
  }));

const ClientAddress = types
  .compose(ClientAddressFields, BaseModel)
  .actions((self) => ({
    setName: (name) => {
      self.name = name;
    },
    setAddress: (address) => {
      self.address = address;
    },
    setAccountNumber: (accountNumber) => {
      self.accountNumber = accountNumber;
    },
    setCashUnit: (cashUnit) => {
      self.cashUnit = cashUnit;
    },
    setType: (type) => {
      self.type = type;
    },
  }));

const ClientAddresses = types
  .compose(Pagination, types.model({ results: types.array(ClientAddress) }))
  .volatile((self) => ({
    endpoint: "client-addresses",
  }));

const ClientAddressByIdReference = types.reference(ClientAddress, {
  async get(value) {
    const response = await requestWithToken.get(`client-addresses/${value}/`);
    let clientAddress = ClientAddress.create({
      ...response.data,
    });
    return clientAddress;
  },
  set(value) {
    return value;
  },
});

export {
  ClientAddresses,
  ClientAddress,
  ClientAddressType,
  ClientAddressFields,
  ClientAddressByIdReference,
  ClientAddressFilters,
};
