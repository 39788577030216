import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import Table from "../../../../components/cards/table";
import { TruckPersonnelFields } from "../../../../data/models/truck-personnel";
import FormDrawer from "../../../../components/form-drawer";
import Form from "../../../../components/cards/form";

const ROUTE_URL = "/config/truck-personnels/";

@inject("truckPersonnelsStore")
@inject("appDataStore")
@observer
class TruckPersonnels extends Component {
  showNewlyAdded = (item) => {
    window.location.assign(`${ROUTE_URL}${item.id}`);
  };
  render() {
    const { truckPersonnelsStore, appDataStore } = this.props;
    const { currentUser } = appDataStore;
    const { canAdd, canView } = currentUser.getPermissions("truckpersonnel");

    return (
      <div className="animated fadeIn">
        <div className="d-flex justify-content-between">
          <div className="config-breadcrumbs ">
            <span className="active">Truck Personnels</span>
          </div>
          {canAdd && (
            <FormDrawer
              title="Add Truck Personnel"
              triggerButton={
                <button
                  className="primary-color-btn"
                  data-testid="form-submit-item"
                >
                  Add Truck Personnel
                </button>
              }
              submitButton={
                <button
                  type="button"
                  className="primary-color-btn"
                  data-testid="form-submit-item"
                  onClick={async () => await this.submitHandler()}
                >
                  Submit
                </button>
              }
            >
              <Form
                mode="form"
                data={TruckPersonnelFields.create({
                  firstName: "",
                  lastName: "",
                  nickname: "",
                  position: "",
                  truck: null,
                  cashUnit: null,
                  provider: null,
                })}
                onSubmit={this.showNewlyAdded}
                submitHandler={(submitHandler) => {
                  this.submitHandler = submitHandler;
                }}
              />
            </FormDrawer>
          )}
        </div>

        <Table
          {...this.props}
          store={truckPersonnelsStore}
          objectKeys={[
            "firstName",
            "lastName",
            "nickname",
            "position",
            "cashUnitIdentifier",
          ]}
          canView={canView}
          linkToPath={ROUTE_URL}
        />
      </div>
    );
  }
}

export default TruckPersonnels;
