import _ from "lodash";
import React from "react";
import { inject, observer } from "mobx-react";
import { getSnapshot, applySnapshot, getType, onPatch } from "mobx-state-tree";

import alertify from "alertifyjs";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";

import { BaseModel } from "../../data/models";

import DisplayInput from "./../../components/display-input";
import Loading from "./../../components/loading";
import { getModelDetails, selectSearch } from "../../utils";

@inject("store")
@observer
class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      instance: null,
      instanceSnapshot: null,
    };
  }

  componentDidMount() {
    const { data, submitHandler } = this.props;
    const instance = data;
    const instanceSnapshot = getSnapshot(instance);

    this.onPatchDisposer = onPatch(instance, (patch) => {
      if (instance.patches) {
        instance.patches[patch.path.replace("/", "")] = patch.value;
      }
    });

    this.setState({
      instance,
      instanceSnapshot,
    });

    if (typeof submitHandler === "function") {
      submitHandler(this.onSubmit);
    }
  }

  componentWillUnmount() {
    const { data } = this.props;
    data.setValue({ patches: {} });
    this.onPatchDisposer && this.onPatchDisposer();
  }

  _getInstanceReadOnlyFields = () => {
    const { instance } = this.state;
    const { readOnlyFields } = this.props;
    let instanceReadOnlyFields = [];
    if (instance.readOnlyFields?.length > 0) {
      instanceReadOnlyFields = [
        ...instanceReadOnlyFields,
        ...instance.readOnlyFields,
      ];
    }
    if (readOnlyFields?.length > 0) {
      instanceReadOnlyFields = [...instanceReadOnlyFields, ...readOnlyFields];
    }

    if (instance.id && instance.nonEditableFields?.length > 0) {
      instanceReadOnlyFields = [
        ...instanceReadOnlyFields,
        ...instance.nonEditableFields,
      ];
    }
    instanceReadOnlyFields = Array.from(new Set([...instanceReadOnlyFields]));

    return instanceReadOnlyFields;
  };

  _getInstanceFieldReferenceOptions = (field) => {
    const { instance, instanceSnapshot } = this.state;
    const { store } = this.props;

    const modelReference = ({ store, name, instance, instanceSnapshot }) => {
      // convert to json to get id instead of Promise to fetch the data.
      const instanceJSON = instance.toJSON();
      let filterOptions = {};
      if (instance.referenceFilterOptions) {
        filterOptions = instance.referenceFilterOptions[name];
      }
      return {
        cashUnit: selectSearch({
          store: store.cashUnitsStore,
          selected: instanceSnapshot.cashUnit,
          filterOptions,
        }),
        schedule: selectSearch({
          store: store.schedulesStore,
          filterOptions,
        }),
        trip: selectSearch({
          store: store.tripsStore,
          selected: instanceSnapshot.trip,
          filterOptions,
        }),
        driver: selectSearch({
          store: store.truckPersonnelsStore,
          filterOptions: {
            position: "DRIVER",
            cashUnit: instanceJSON.cashUnit,
            ...filterOptions,
          },
        }),
        escort: selectSearch({
          store: store.truckPersonnelsStore,
          filterOptions: {
            position: "ESCORT",
            cashUnit: instanceJSON.cashUnit,
            ...filterOptions,
          },
        }),
        teamLeader: selectSearch({
          store: store.truckPersonnelsStore,
          filterOptions: {
            position: "TEAM_LEADER",
            cashUnit: instanceJSON.cashUnit,
            ...filterOptions,
          },
        }),
        group: selectSearch({
          store: store.groupsStore,
          selected: instanceSnapshot.group,
          filterOptions,
        }),
        provider: selectSearch({
          store: store.truckProvidersStore,
          selected: instanceSnapshot.provider,
        }),
        user: selectSearch({
          store: store.usersStore,
          selected: instanceSnapshot.user,
          filterOptions,
        }),
        clientAddress: selectSearch({
          store: store.clientAddressesStore,
          selected: instanceSnapshot.clientAddress,
          filterOptions,
        }),
        cifId: selectSearch({
          store: store.cifIdsStore,
          selected: instanceSnapshot.cifId,
          filterOptions,
        }),
        masterCif: selectSearch({
          store: store.masterCifsStore,
          selected: instanceSnapshot.masterCif,
          filterOptions,
        }),
        teller: selectSearch({
          store: store.cashUnitPersonnelsStore,
          filterOptions,
          selected: instanceSnapshot.teller,
        }),
        truck: selectSearch({
          store: store.trucksStore,
          filterOptions: {
            // cashUnit: instanceJSON.cashUnit,
            ...filterOptions,
          },
          selected: instanceSnapshot.truck,
        }),
      }[name];
    };

    let referenceSelectSearch = null;
    referenceSelectSearch = modelReference({
      name: field.name,
      instance,
      instanceSnapshot,
      store,
    });
    const _loadOptions = referenceSelectSearch.loadOptions;
    // create a class member debounced func to avoid being re-evaluated every render.
    this["debouncedFunc" + _.startCase(field.name)] = _.debounce(
      async (value, callback) => {
        await _loadOptions(value, callback);
      },
      500
    );
    referenceSelectSearch.loadOptions = async (value, callback) => {
      await this["debouncedFunc" + _.startCase(field.name)](value, callback);
    };
    return referenceSelectSearch;
  };

  getFieldParams = (field) => {
    const { instance } = this.state;
    const instanceReadOnlyFields = this._getInstanceReadOnlyFields();
    const readOnly = instanceReadOnlyFields.includes(field.name);
    const params = {
      type: field.type,
      name: field.name,
      value: field.value,
      required: typeof field.default === "undefined",
      disabled: instance.isLoading || instance.disabled?.includes(field.name),
      isLoading: instance.isLoading,
      callback: (evt) => {
        let value = evt;
        if (evt && evt.hasOwnProperty("target")) {
          value = evt.target.value;
        } else if (evt && evt.hasOwnProperty("value")) {
          value = evt.value;
        }
        instance.setValue({
          key: field.name,
          value: value,
        });
      },
      child: () =>
        Object.entries(getType(instance).properties)
          .find(([key, value]) => field.name === key)[1]
          ._types.find((t) => t.name === "Enum")
          ._types.map((type, index) =>
            type.value === "" ? (
              <option value=""> ----- </option>
            ) : (
              <option key={index} value={type.value}>
                {type.name.replace(/['"]+/g, "")}
              </option>
            )
          ),
      searchableSelectOptions:
        field.type === "reference"
          ? this._getInstanceFieldReferenceOptions(field)
          : null,
      readOnly,
    };

    return params;
  };

  onSubmit = async () => {
    const { onSubmit, onClose } = this.props;
    const { instance } = this.state;
    let response;
    if (getType(instance).propertyNames.includes("id")) {
      if (instance.patches && Object.keys(instance.patches).length > 0) {
        response = await instance.update();
      }
    } else {
      response = await instance.create();
    }
    if (response && response.status >= 200 && response.status < 300) {
      if (onSubmit) {
        // create
        alertify.success("Create success");
        onSubmit(response.data);
      } else {
        // update
        alertify.success("Update success");
        this.setState({
          instanceSnapshot: getSnapshot(instance),
        });
      }

      if (onClose) {
        onClose();
      }
    } else if (response && response.status >= 400 && response.status < 500) {
      try {
        Object.entries(response.data)
          .map(([key, value]) => {
            return `${_.startCase(key).toUpperCase()} - ${(Array.isArray(value)
              ? value
              : [value]
            ).join()}`;
          })
          .forEach((message) => {
            alertify.error(message);
          });
      } catch {
        alertify.error("Something went wrong.");
      }
    } else if (instance.patches && Object.keys(instance.patches).length === 0) {
      alertify.message("No change to apply");
    } else {
      alertify.error("Internal Server Error", response);
    }
  };

  onFormSubmit = async (event) => {
    event.preventDefault();
    await this.onSubmit();
    return false;
  };

  render() {
    const { instanceSnapshot, instance } = this.state;

    const { headerTitle, onClose, mode } = this.props;

    if (!instance || !instanceSnapshot) {
      return <Loading />;
    }

    const { hiddenFields, readOnlyFields } = instance;

    let targetFields = getType(instance).propertyNames.filter(
      (name) => !BaseModel.propertyNames.includes(name)
    );

    if (hiddenFields) {
      targetFields = targetFields.filter(
        (name) => !hiddenFields.includes(name)
      );
    }
    if (readOnlyFields) {
      targetFields = targetFields.filter(
        (name) => !readOnlyFields.includes(name)
      );
    }
    const modelDetails = getModelDetails(instance, targetFields);
    const modelMetadata = getModelDetails(instance, ["isActive"]);

    return (
      <div className="config-card-details">
        {headerTitle && mode !== "form" && (
          <div className="config-card__header">
            <div className="config-card__header-title">{headerTitle}</div>
            <div className="config-card__header-button">
              {mode !== "form" && (
                <div>
                  <button
                    type="submit"
                    data-testid="form-submit-item"
                    onClick={this.onSubmit}
                    className="primary-color-btn"
                    disabled={instance.isLoading}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </button>
                  <button
                    type="button"
                    className="secondary-color-btn"
                    data-testid="form-cancel-item"
                    disabled={instance.isLoading}
                    onClick={async () => {
                      if (!_.isEqual(getSnapshot(instance), instanceSnapshot)) {
                        applySnapshot(instance, instanceSnapshot);
                      }
                      if (onClose) {
                        onClose();
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="config-card__body">
          <div className="config-card__details-container">
            {modelDetails.map((field) => {
              const params = this.getFieldParams(field);
              return (
                !params.readOnly && (
                  <div
                    data-testid={field.name}
                    key={field.name}
                    className="cashcenter-card__detail-item"
                  >
                    <span className="label">
                      {_.startCase(field.name).toUpperCase()}
                    </span>
                    <span className="value">
                      <DisplayInput {...params} />
                    </span>
                  </div>
                )
              );
            })}

            {modelMetadata.map((field) => {
              const params = this.getFieldParams(field);
              return (
                <div
                  data-testid={field.name}
                  key={field.name}
                  className="cashcenter-card__detail-item"
                >
                  <span className="label">
                    {_.startCase("isActive").toUpperCase()}
                  </span>
                  <span className="value">
                    <DisplayInput {...params} />
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Form;
