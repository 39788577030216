import _ from "lodash";

import { types, getSnapshot, flow } from "mobx-state-tree";
import { requestWithToken } from "../../api";
import {
  removeNullProperties,
  objToQueryString,
  camelCaseKeysToSnakeCase,
} from "../../utils";

/*
  This ModelMixin types provide the actions that are 
  used to provide basic actions with API endpoints.
*/

const CreateModelMixin = types
  .model()
  .volatile((self) => ({
    isLoading: false,
  }))
  .actions((self) => ({
    create: flow(function* create() {
      self.isLoading = true;
      let response;
      try {
        response = yield requestWithToken.post(
          `${self.endpoint}/`,
          getSnapshot(self)
        );
      } catch (error) {
        response = error.response;
      } finally {
        self.isLoading = false;
      }
      return response;
    }),
    setValue: ({ key, value }) => {
      self[key] = value;
    },
  }));

const UpdateModelMixin = types
  .model()
  .volatile((self) => ({
    isLoading: false,
  }))
  .actions((self) => ({
    update: flow(function* update() {
      self.isLoading = true;
      let response;
      try {
        response = yield requestWithToken.patch(
          `${self.endpoint}/${self.id}/`,
          JSON.stringify(self.patches)
        );
        self.patches = {};
        Object.assign(self, response.data);
      } catch (error) {
        response = error.response;
      } finally {
        self.isLoading = false;
      }
      return response;
    }),
  }));

const DestroyModelMixin = types
  .model()
  .volatile((self) => ({
    isLoading: false,
  }))
  .actions((self) => ({
    destroy: flow(function* destroy() {
      self.isLoading = true;
      let response;
      try {
        response = yield requestWithToken.delete(
          `${self.endpoint}/${self.id}/`
        );
        Object.assign(self, response.data);
      } catch (error) {
        response = error.response;
      } finally {
        self.isLoading = false;
      }
      return response;
    }),
  }));

const ListModelMixin = types
  .model()
  .volatile((self) => ({
    isLoading: false,
  }))
  .actions((self) => ({
    list: flow(function* list(params) {
      const defaultParams = {
        page: 1,
        search: "",
        ...self?.filterOptions,
      };
      if (params) {
        params = removeNullProperties(params);
        // resolve all promise in params
        params = _.zipObject(
          _.keys(params),
          yield Promise.all(_.values(params))
        );
      }
      for (const key in params) {
        if (params[key] === "") {
          delete params[key];
        }
      }
      for (const key in params) {
        if (params[key] && params[key].hasOwnProperty("id")) {
          params[key] = params[key].id;
        }
      }
      params = Object.assign(defaultParams, params);
      params = camelCaseKeysToSnakeCase(params);
      params = _.pickBy(params, (v) => v !== undefined);
      self.isLoading = true;
      let response;
      try {
        response = yield requestWithToken.get(
          `${self.endpoint}/?${objToQueryString(params)}`
        );
        Object.assign(self, response.data);
      } catch (error) {
        console.error(error);
        response = error.response;
      } finally {
        self.isLoading = false;
      }
      return response;
    }),
  }));

const ActionMixin = types.model().actions((self) => ({
  setValue: ({ key, value }) => {
    self[key] = value;
  },
}));

const RetrieveModelMixin = types
  .model()
  .volatile((self) => ({
    isLoading: false,
  }))
  .actions((self) => ({
    retrieve: flow(function* retrieve(id) {
      self.isLoading = true;
      let response;
      try {
        response = yield requestWithToken.get(`${self.endpoint}/${id}/`);
      } catch (error) {
        console.error(error);
      } finally {
        self.isLoading = false;
      }
      return response;
    }),
  }));

export {
  CreateModelMixin,
  ListModelMixin,
  UpdateModelMixin,
  RetrieveModelMixin,
  DestroyModelMixin,
  ActionMixin,
};
