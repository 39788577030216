import { types } from "mobx-state-tree";

import { BaseModel, Pagination, BaseFilters } from ".";
import { requestWithToken } from "../../api";
import { DateTimeType } from "./datetime-type";

import { CreateModelMixin, RetrieveModelMixin } from "./mixins";
import { Schedule, ScheduleByIdReference } from "./schedule";
import { DetailedTrip, Trip, TripByIdReference } from "./trip";

const TripScheduleState = types.enumeration("Enum", [
  "PENDING",
  "IN_TRANSIT",
  "ARRIVED",
  "TRANSACT",
  "REVIEW",
  "DONE",
]);

const TripScheduleFilters = types.compose(
  BaseFilters,
  types.model({
    state: types.union(TripScheduleState, types.literal("")),
  })
);

const TripScheduleFields = types
  .model({
    schedule: types.union(
      ScheduleByIdReference,
      Schedule,
      types.integer,
      types.null,
      types.undefined
    ),
    arrivalTime: types.maybeNull(DateTimeType),
    departureTime: types.maybeNull(DateTimeType),
    state: types.union(TripScheduleState, types.literal("PENDING")),
    order: types.integer,
    tripName: types.maybeNull(types.string),
    trip: types.union(
      types.late(() => TripByIdReference),
      types.late(() => Trip),
      types.integer,
      types.null,
      types.undefined
    ),
    scheduleName: types.maybeNull(types.string),
  })
  .volatile((self) => ({
    endpoint: "trip-schedules",
    readOnlyFields: [
      // "departureTime",
      // "arrivalTime",
      // "order",
      // "state",
      // "clientAddress",
    ],
    hiddenFields: ["tripName", "scheduleName"],
  }))
  .views((self) => ({
    get display() {
      return `${self.tripName} - ${self.scheduleName}`;
    },
  }));

// const TripScheduleFields = types.compose(
//   CreateModelMixin,
//   RetrieveModelMixin,
//   BaseTripScheduleFields,
//   types.model({
//     trip: types.union(
//       types.late(() => TripByIdReference),
//       types.late(() => Trip),
//       types.integer,
//       types.null,
//       types.undefined
//     ),
//   })
// );

const DetailedTripScheduleFields = types.compose(
  CreateModelMixin,
  RetrieveModelMixin,
  TripScheduleFields,
  types.model({
    trip: types.union(
      types.late(() => TripByIdReference),
      types.late(() => DetailedTrip),
      types.integer,
      types.null,
      types.undefined
    ),
  })
);

const TripSchedule = types.compose(TripScheduleFields, BaseModel);

const DetailedTripSchedule = types.compose(
  DetailedTripScheduleFields,
  BaseModel
);

const TripSchedules = types
  .compose(Pagination, types.model({ results: types.array(TripSchedule) }))
  .volatile((self) => ({
    endpoint: "trip-schedules",
  }));

const TripScheduleByIdReference = types.reference(TripSchedule, {
  async get(value) {
    const response = await requestWithToken.get(`trip-schedules/${value}/`);
    let obj = TripSchedule.create({
      ...response.data,
    });
    return obj;
  },
  set(value) {
    return value;
  },
});

export {
  TripScheduleFields,
  TripSchedule,
  DetailedTripSchedule,
  TripSchedules,
  TripScheduleByIdReference,
  TripScheduleFilters,
};
