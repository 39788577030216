import React, { Component } from "react";

import { observer, inject } from "mobx-react";
import { getChildType } from "mobx-state-tree";

import Details from "./../../../../components/cards/details";
import Form from "./../../../../components/cards/form";
import NotFoundPage from "../../../others/not-found-page";

import Table from "../../../../components/cards/table";
import { ScheduleFields, Schedules } from "../../../../data/models/schedule";
import withAuthorization from "../../../../components/authorization";
import Loading from "../../../../components/loading";

const schedulesStore = Schedules.create({
  count: 0,
  results: [],
  next: null,
  previous: null,
});

const formData = ScheduleFields.create({
  dayOfWeek: "",
  servicingType: "",
  verificationProcess: "",
  clientAddress: null,
  sameDayRequestOnly: false,
  groupName: "",
});

@inject("appDataStore")
@inject("clientAddressesStore")
@observer
@withAuthorization({
  allowedRoles: ["cash_services_admin", "cash_unit_admin"],
  permissions: ["view_clientaddress"],
})
class ClientAddressPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientAddress: undefined,
      isEditMode: false,
    };
  }
  async componentDidMount() {
    const { clientAddressesStore } = this.props;
    const { clientAddressId } = this.props.match.params;
    const response = await clientAddressesStore.retrieve(clientAddressId);
    if (response) {
      const clientAddress = getChildType(clientAddressesStore.results).create(
        response.data
      );
      formData.setValue({ key: "clientAddress", value: clientAddress.id });
      this.setState({ clientAddress });
    } else {
      this.setState({ clientAddress: null });
    }
  }

  toggleEditMode = () => {
    const { isEditMode } = this.state;
    this.setState({ isEditMode: !isEditMode });
  };

  render() {
    const { clientAddressesStore, appDataStore } = this.props;
    const { clientAddress, isEditMode } = this.state;

    const { currentUser } = appDataStore;
    const { canChange, canDelete } =
      currentUser.getPermissions("clientaddress");

    if (clientAddress === null) {
      return <NotFoundPage />;
    } else if (clientAddress === undefined) {
      return <Loading />;
    }

    return (
      <div className="animated fadeIn">
        <div className="row">
          <div className="col-8">
            <div className="card-container w-100">
              {!isEditMode && (
                <Details
                  data={clientAddress}
                  isLoading={clientAddressesStore.isLoading}
                  headerTitle="CLIENT ADDRESS DETAILS"
                  onEdit={this.toggleEditMode}
                  editable={canChange}
                  deletable={canDelete}
                />
              )}
              {isEditMode && (
                <Form
                  data={clientAddress}
                  headerTitle="EDIT CLIENT ADDRESS DETAILS"
                  onClose={this.toggleEditMode}
                />
              )}
            </div>
          </div>
        </div>

        <div>
          {clientAddress && (
            <Table
              {...this.props}
              store={schedulesStore}
              filter={{ client_address: clientAddress.id }}
              objectKeys={[
                "dayOfWeek",
                "servicingType",
                "verificationProcess",
                "sameDayRequestOnly",
              ]}
              linkToPath={"/config/schedules/"}
              form={{
                data: formData,
                readOnlyFields: formData.sameDayRequestOnly
                  ? ["clientAddress", "dayOfWeek"]
                  : ["clientAddress"],
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ClientAddressPreview;
