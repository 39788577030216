import React, { Component } from "react";

import Details from "./cards/details";
import Form from "./cards/form";
import Loading from "./loading";
import DetailsNotFound from "./details-not-found";

class EditableDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
    };
  }

  toggleEditMode = () => {
    const { isEditMode } = this.state;
    this.setState({ isEditMode: !isEditMode });
  };

  render() {
    const { isEditMode } = this.state;
    const { instance, canChange, canDelete, title } = this.props;

    if (instance === undefined) {
      return <Loading />;
    } else if (instance === null) {
      return <DetailsNotFound />;
    }

    return (
      <>
        {isEditMode ? (
          <Form
            data={instance}
            headerTitle={`Edit ${title}`.toUpperCase()}
            onClose={this.toggleEditMode}
          />
        ) : (
          <Details
            data={instance}
            isLoading={instance.isLoading}
            headerTitle={`${title} Details`.toUpperCase()}
            onEdit={this.toggleEditMode}
            editable={canChange}
            deletable={canDelete}
          />
        )}
      </>
    );
  }
}

export default EditableDetails;
