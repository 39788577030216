import { types, flow, getSnapshot } from "mobx-state-tree";

import { requestWithToken } from "../../api";
import { ClientAddressByIdReference, ClientAddress } from "./client-address";
import { RetrieveModelMixin, ActionMixin } from "./mixins";
import { BaseModel, Pagination, BaseFilters } from ".";
import { GroupByIdReference } from "./group";
import moment from "moment";

const ScheduleServicingType = types.enumeration("Enum", [
  "PICK_UP",
  "DELIVERY",
  "PICK_UP_AND_DELIVERY",
  "CHANGE_FUND_CURRENCY",
  "CHANGE_FUND_COINS_TO_BILLS",
  "CHANGE_FUND_BILLS_TO_COINS",
]);
const ScheduleDayOfWeekList = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
];
const ScheduleDayOfWeek = types.enumeration("Enum", ScheduleDayOfWeekList);
const ScheduleVerificationProcess = types.enumeration("Enum", [
  "BUNDLE_COUNT",
  "LOCKED_BAG",
  "PIECE_COUNT",
]);

const ScheduleFilters = types.compose(
  BaseFilters,
  types.model({
    verificationProcess: types.union(
      ScheduleVerificationProcess,
      types.literal("")
    ),
    servicingType: types.union(ScheduleServicingType, types.literal("")),
    dayOfWeek: types.union(ScheduleDayOfWeek, types.literal("")),
    group: types.maybeNull(GroupByIdReference),
  })
);

const ScheduleFields = types
  .compose(
    // CreateModelMixin,
    ActionMixin,
    RetrieveModelMixin,
    types.model({
      sameDayRequestOnly: types.boolean,
      dayOfWeek: types.union(ScheduleDayOfWeek, types.literal("")),
      servicingType: types.union(ScheduleServicingType, types.literal("")),
      verificationProcess: types.union(
        ScheduleVerificationProcess,
        types.literal("")
      ),
      clientAddress: types.union(
        ClientAddressByIdReference,
        ClientAddress,
        types.integer,
        types.null
      ),
      clientAddressName: types.maybeNull(types.string),
      // preferredTime: types.union(TimeType, types.null, types.string),
      groupName: types.maybeNull(types.string),
    })
  )
  .volatile((self) => ({
    endpoint: "schedules",
    readOnlyFields: ["groupName"],
    nonEditableFields: [
      "sameDayRequestOnly",
      // "dayOfWeek",
      // "servicingType",
      // "verificationProcess",
      // "clientAddress",
    ],
    hiddenFields: ["clientAddressName"],
  }))
  .views((self) => ({
    get display() {
      return `${self.clientAddressName} - ${self.servicingType} - ${self.verificationProcess}`;
    },
  }))
  .actions((self) => ({
    create: flow(function* create() {
      self.isLoading = true;
      let response;
      try {
        if (self.sameDayRequestOnly) {
          const date = moment();
          const dayOfWeek = ScheduleDayOfWeekList[date.day()];
          self.dayOfWeek = dayOfWeek;
        }
        response = yield requestWithToken.post(
          `${self.endpoint}/`,
          getSnapshot(self)
        );
      } catch (error) {
        response = error.response;
      } finally {
        self.isLoading = false;
      }
      return response;
    }),
  }));

const Schedule = types.compose(BaseModel, ScheduleFields);

const Schedules = types
  .compose(Pagination, types.model({ results: types.array(Schedule) }))
  .volatile((self) => ({
    endpoint: "schedules",
  }));

const ScheduleByIdReference = types.reference(Schedule, {
  async get(value) {
    const response = await requestWithToken.get(`schedules/${value}/`);
    const obj = Schedule.create(response.data);
    return obj;
  },
  set(value) {
    return value;
  },
});

export {
  Schedule,
  Schedules,
  ScheduleFields,
  ScheduleServicingType,
  ScheduleDayOfWeek,
  ScheduleVerificationProcess,
  ScheduleByIdReference,
  ScheduleFilters,
};
