import { flow, types } from "mobx-state-tree";
import { requestWithTokenNoBaseUrl, requestWithToken } from "../../api";
import { DateTimeType } from "./datetime-type";

import {
  ActionMixin,
  UpdateModelMixin,
  DestroyModelMixin,
  ListModelMixin,
  RetrieveModelMixin,
  CreateModelMixin,
} from "./mixins";

/*
  accessing `self` object here will still have all the combined fields from the other objects.
*/

const BaseFilters = types.compose(
  ActionMixin,
  types.model({
    search: types.maybeNull(types.string),
    isActive: types.union(
      types.enumeration("Enum", ["True", "False"]),
      types.literal("")
    ),
  })
);

const BaseFields = types
  .model({
    id: types.maybeNull(types.integer),
    createdTime: types.maybeNull(DateTimeType),
    modifiedTime: types.maybeNull(DateTimeType),
    createdBy: types.union(
      types.late(() => UserByIdReference),
      types.late(() => User),
      types.integer,
      types.null
    ),
    modifiedBy: types.union(
      types.late(() => UserByIdReference),
      types.late(() => User),
      types.integer,
      types.null
    ),
    isActive: types.maybeNull(types.boolean),
  })
  .volatile((self) => ({
    isLoading: false,
  }));

const BaseModel = types
  .compose(UpdateModelMixin, DestroyModelMixin, BaseFields)
  .volatile((self) => ({
    patches: {},
  }))
  .actions((self) => ({
    setIsActive: (isActive) => {
      self.isActive = isActive;
    },
    setValue: ({ key, value }) => {
      self[key] = value;
    },
  }));

const PaginationFields = types
  .model({
    count: types.integer,
    next: types.maybeNull(types.string),
    previous: types.maybeNull(types.string),
  })
  .volatile((self) => ({
    isLoading: false,
  }));

const Pagination = types
  .compose(ListModelMixin, RetrieveModelMixin, PaginationFields)
  .views((self) => ({
    get pageNum() {
      if (
        (self.next === null && self.previous === null) ||
        self.previous === null
      ) {
        return 1;
      } else if (self.next === null) {
        return self.totalPageNum;
      }

      const nextUrlParams = new URLSearchParams(self.next.split("?")[1]);
      const previousUrlParams = new URLSearchParams(
        self.previous.split("?")[1]
      );

      const nextPageNum = parseInt(nextUrlParams.get("page"));
      const previousPageNum = parseInt(previousUrlParams.get("page"));

      if (nextPageNum) {
        return nextPageNum - 1;
      } else {
        return previousPageNum + 1;
      }
    },
    get totalPageNum() {
      return Math.ceil(self.count / 20) || 1;
    },
  }))
  .actions((self) => ({
    nextPage: flow(function* nextPage() {
      self.isLoading = true;
      let response;
      try {
        response = yield requestWithTokenNoBaseUrl.get(self.next);
        Object.assign(self, response.data);
      } catch (error) {
        console.error(error);
        response = error.response;
      } finally {
        self.isLoading = false;
      }
      return response;
    }),
    previousPage: flow(function* previousPage() {
      self.isLoading = true;
      let response;
      try {
        response = yield requestWithTokenNoBaseUrl.get(self.previous);
        Object.assign(self, response.data);
      } catch (error) {
        console.error(error);
        response = error.response;
      } finally {
        self.isLoading = false;
      }
      return response;
    }),
    setValue: ({ key, value }) => {
      self[key] = value;
    },
    clear: () => {
      self.results = [];
      self.count = 0;
      self.previous = null;
      self.next = null;
      self.isLoading = false;
    },
  }));

const UserFields = types.compose(
  CreateModelMixin,
  types
    .model({
      username: types.string,
      email: types.string,
      permissionGroups: types.array(types.string),
      permissions: types.array(types.string),
    })
    .views((self) => ({
      get display() {
        return self.username;
      },
    }))
    .actions((self) => ({
      getPermissions: (appLabel) => {
        const canView = self.permissions.includes(`view_${appLabel}`);
        const canChange = self.permissions.includes(`change_${appLabel}`);
        const canDelete = self.permissions.includes(`delete_${appLabel}`);
        const canAdd = self.permissions.includes(`add_${appLabel}`);
        return { canAdd, canChange, canDelete, canView };
      },
      getFieldPermission: (appLabel, field) => {
        return self.permissions.includes(`change_${appLabel}_${field}`);
      },
    }))
    .volatile((self) => ({
      endpoint: "users",
      hiddenFields: ["permissionGroups", "permissions"],
    }))
);

const User = types.compose(BaseModel, UserFields);

const Users = types
  .compose(Pagination, types.model({ results: types.array(User) }))
  .volatile((self) => ({
    endpoint: "users",
  }));
const UserByIdReference = types.reference(User, {
  async get(value) {
    const response = await requestWithToken.get(`users/${value}/`);
    const user = User.create({
      ...response.data,
    });
    return user;
  },
  set(value) {
    return value;
  },
});

export {
  BaseModel,
  BaseFields,
  Pagination,
  UserFields,
  User,
  Users,
  UserByIdReference,
  BaseFilters,
};
