import { types } from "mobx-state-tree";

import { requestWithToken } from "../../api";

import { BaseModel, Pagination } from ".";
import { CreateModelMixin, RetrieveModelMixin } from "./mixins";
import { CashUnit, CashUnitByIdReference } from "./cash-unit";

import { TruckProviderByIdReference, TruckProvider } from "./truck-provider";

const TruckPersonnelPosition = types.enumeration("Enum", [
  "ESCORT",
  "DRIVER",
  "TEAM_LEADER",
]);

let _TruckPersonnelFields = types.model({
  firstName: types.string,
  lastName: types.string,
  // fullName: types.maybe(types.string),
  nickname: types.string,
  cashUnit: types.union(
    CashUnitByIdReference,
    CashUnit,
    types.integer,
    types.null
  ),
  position: types.union(TruckPersonnelPosition, types.literal("")),
  provider: types.union(
    TruckProviderByIdReference,
    TruckProvider,
    types.integer,
    types.null
  ),
  cashUnitIdentifier: types.maybeNull(types.string),
});

const TruckPersonnelFields = types
  .compose(CreateModelMixin, RetrieveModelMixin, _TruckPersonnelFields)
  .volatile((self) => ({
    endpoint: "truck-personnels",
    hiddenFields: ["cashUnitIdentifier"],
  }))
  .views((self) => ({
    get fullName() {
      return `${self.firstName} ${self.lastName}`.trim();
    },
    get display() {
      return self.fullName;
    },
  }));

const TruckPersonnel = types.compose(BaseModel, TruckPersonnelFields);

const TruckPersonnels = types
  .compose(
    Pagination,
    types.model({
      results: types.array(TruckPersonnel),
    })
  )
  .volatile((self) => ({
    endpoint: "truck-personnels",
  }));

const TruckPersonnelByIdReference = types.reference(TruckPersonnel, {
  async get(value) {
    const response = await requestWithToken.get(`truck-personnels/${value}/`);
    let truckPersonnel = TruckPersonnel.create({
      ...response.data,
    });
    return truckPersonnel;
  },
  set(value) {
    return value;
  },
});

export {
  TruckPersonnelFields,
  TruckPersonnel,
  TruckPersonnels,
  TruckPersonnelByIdReference,
};
