import React, { Component } from "react";

import LogoAdi from "../logo-adi";

class FooterNavbar extends Component {
  render() {
    return (
      <div className="border-top" style={{ zIndex: "-1" }}>
        <div className="d-flex justify-content-end opacity-2">
          <LogoAdi />
        </div>
      </div>
    );
  }
}

export default FooterNavbar;
