import React from "react";

import { observer, inject } from "mobx-react";
import Table from "../../../../components/cards/table";
import {
  TripScheduleFields,
  TripScheduleFilters,
} from "../../../../data/models/trip-schedule";
import FormDrawer from "../../../../components/form-drawer";
import Form from "../../../../components/cards/form";

const ROUTE_URL = "/config/trip-schedules/";

@inject("tripSchedulesStore")
@inject("appDataStore")
@observer
class TripSchedules extends React.Component {
  showNewlyAdded = (item) => {
    window.location.assign(`${ROUTE_URL}${item.id}`);
  };

  render() {
    const { tripSchedulesStore, appDataStore } = this.props;
    const { currentUser } = appDataStore;
    const { canAdd, canView } = currentUser.getPermissions("tripschedule");

    return (
      <div className="animated fadeIn">
        <div className="d-flex justify-content-between">
          <div className="config-breadcrumbs ">
            <span className="active">Trip Schedules</span>
          </div>
          {canAdd && (
            <FormDrawer
              title="Add Trip Schedule"
              triggerButton={
                <button
                  className="primary-color-btn"
                  data-testid="form-submit-item"
                >
                  Add Trip Schedule
                </button>
              }
              submitButton={
                <button
                  type="button"
                  className="primary-color-btn"
                  data-testid="form-submit-item"
                  onClick={async () => await this.submitHandler()}
                >
                  Submit
                </button>
              }
            >
              <Form
                mode="form"
                data={TripScheduleFields.create({
                  schedule: null,
                  arrivalTime: null,
                  departureTime: null,
                  state: "PENDING",
                  order: 0,
                })}
                onSubmit={this.showNewlyAdded}
                submitHandler={(submitHandler) => {
                  this.submitHandler = submitHandler;
                }}
              />
            </FormDrawer>
          )}
        </div>

        <Table
          {...this.props}
          store={tripSchedulesStore}
          filterFields={TripScheduleFilters.create({ state: "", isActive: "" })}
          objectKeys={[
            "tripName",
            "scheduleName",
            "clientAddress",
            "state",
            "arrivalTime",
            "departureTime",
          ]}
          canView={canView}
          linkToPath={ROUTE_URL}
        />
      </div>
    );
  }
}

export default TripSchedules;
