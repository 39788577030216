import { types } from "mobx-state-tree";

import { BaseModel, Pagination } from ".";
import { CashUnit, CashUnitByIdReference } from "./cash-unit";
import { TruckProviderByIdReference } from "./truck-provider";
import { TruckPersonnel, TruckPersonnelByIdReference } from "./truck-personnel";
import { CreateModelMixin, RetrieveModelMixin } from "./mixins";
import { requestWithToken } from "../../api";

const TruckStatus = types.enumeration("Enum", [
  "WORKING",
  "REPAIR",
  "MAINTENANCE",
]);

const TruckType = types.enumeration("Enum", ["FOUR_WHEELS", "SIX_WHEELS"]);

const TruckFields = types
  .compose(
    CreateModelMixin,
    RetrieveModelMixin,
    types.model({
      callSign: types.string,
      plateNumber: types.string,
      bodyNumber: types.string,
      status: types.union(TruckStatus, types.literal("")),
      type: types.union(TruckType, types.literal(""), types.null),
      provider: types.union(
        TruckProviderByIdReference,
        types.integer,
        types.null
      ),
      cashUnit: types.union(
        CashUnitByIdReference,
        CashUnit,
        types.integer,
        types.null
      ),
      driver: types.union(
        TruckPersonnelByIdReference,
        TruckPersonnel,
        types.integer,
        types.null
      ),
      providerName: types.maybeNull(types.string),
      cashUnitIdentifier: types.maybeNull(types.string),
    })
  )
  .volatile((self) => ({
    endpoint: "trucks",
    hiddenFields: ["providerName", "cashUnitIdentifier"],
  }))
  .views((self) => ({
    get disabled() {
      let disabled = [];
      if (self.cashUnit === null) {
        disabled.push("driver");
      }
      return disabled;
    },
    get display() {
      return `${self.callSign} - ${self.bodyNumber} - ${self.plateNumber}`;
    },
  }))
  .actions((self) => ({
    setValue: ({ key, value }) => {
      if (key === "cashUnit") {
        self.driver = null;
      }
      self[key] = value;
    },
  }));

const Truck = types.compose(BaseModel, TruckFields);

const Trucks = types
  .compose(
    Pagination,
    types.model({
      results: types.array(Truck),
    })
  )
  .volatile((self) => ({
    endpoint: "trucks",
  }));

const TruckByIdReference = types.reference(Truck, {
  async get(value) {
    const response = await requestWithToken.get(`trucks/${value}/`);
    let obj = Truck.create({
      ...response.data,
    });
    return obj;
  },
  set(value) {
    return value;
  },
});

export { Trucks, Truck, TruckFields, TruckByIdReference };
