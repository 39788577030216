import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

@withRouter
class UsersNavbar extends Component {
  render() {
    let path = this.props.location.pathname;
    const links = [
      {
        url: "/users/active",
        label: "Active Users",
      },
      {
        url: "/users/inactive",
        label: "Inactive Users",
      },
    ];
    return (
      <div className="config-navbar">
        <div className="config-navbar__page-name">Users</div>
        <div className="config-navbar__navigation">
          {links.map((link, index) => (
            <Link key={index} to={link.url}>
              <div
                className={`config-navbar__nav-item ${
                  path.includes(link.url) ? "active" : ""
                }`}
              >
                <span className="config-navbar__nav-item-text">
                  {link.label}
                </span>
                <FontAwesomeIcon
                  className={`config-navbar__nav-item-icon ${
                    path.includes(link.url) ? "" : "d-none"
                  }`}
                  icon={faChevronRight}
                />
              </div>
            </Link>
          ))}
        </div>
      </div>
    );
  }
}
export default UsersNavbar;
