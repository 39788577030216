import { types } from "mobx-state-tree";

import { requestWithToken } from "../../api";

import { BaseModel, Pagination } from ".";

import { CreateModelMixin, RetrieveModelMixin } from "./mixins";

const AddressGeocodeFields = types
  .compose(
    CreateModelMixin,
    RetrieveModelMixin,
    types.model({
      address: types.string,
      latitude: types.string,
      longitude: types.string,
    })
  )
  .views((self) => ({
    get display() {
      return `
      ${self.address} - 
      ${Number(self.latitude).toFixed(6)}, 
      ${Number(self.longitude).toFixed(6)}
    `;
    },
  }))
  .volatile((self) => ({
    endpoint: "address-geocodes",
  }));

const AddressGeocode = types.compose(AddressGeocodeFields, BaseModel);

const AddressGeocodes = types
  .compose(
    Pagination,
    types.model({
      results: types.array(AddressGeocode),
    })
  )
  .volatile((self) => ({
    endpoint: "address-geocodes",
  }));

const AddressGeocodeByIdReference = types.reference(AddressGeocode, {
  async get(value) {
    const response = await requestWithToken.get(`address-geocodes/${value}/`);
    let instance = AddressGeocode.create({
      ...response.data,
    });
    return instance;
  },
  set(value) {
    return value;
  },
});

export {
  AddressGeocode,
  AddressGeocodes,
  AddressGeocodeFields,
  AddressGeocodeByIdReference,
};
