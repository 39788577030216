import React, { Component } from "react";
import { Link, withRouter, NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTasks,
  faUsers,
  faCog,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

import Logo from "./../logo";
import { getLatestAppRelease } from "../../api/others";

class SideNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appRelease: null,
    };
  }

  componentDidMount() {
    console.log("mounteds");
    getLatestAppRelease((response) => {
      this.setState({ appRelease: response?.data });
    });
  }

  render() {
    // let path = this.props.match.path;
    // console.log(path === '/');
    return (
      <div className="navbar-side d-flex flex-column min-vh-100">
        <div className="navbar-side__logo-container">
          <Link to="/">
            <Logo width="35" height="46" alt={true} />
          </Link>
        </div>
        <div className="navbar-side__navigation h-100">
          <NavLink
            exact
            activeClassName="navigation-item active"
            className="navigation-item"
            to="/"
          >
            <FontAwesomeIcon className="navigation-item__icon" icon={faTasks} />
            <span className="navigation-item__text">Dashboard</span>
          </NavLink>
          <NavLink
            activeClassName="navigation-item active"
            className="navigation-item"
            to="/users"
          >
            <FontAwesomeIcon className="navigation-item__icon" icon={faUsers} />
            <span className="navigation-item__text">Users</span>
          </NavLink>
          <NavLink
            activeClassName="navigation-item active"
            className="navigation-item"
            to="/config"
          >
            <FontAwesomeIcon className="navigation-item__icon" icon={faCog} />
            <span className="navigation-item__text">Configuration</span>
          </NavLink>
          <hr />
          {(this.state.appRelease?.artifact ||
            this.state.appRelease?.downloadUrl) && (
            <a
              className="navigation-item"
              href={
                this.state.appRelease?.artifact ||
                this.state.appRelease?.downloadUrl
              }
              download={Boolean(this.state.appRelease?.artifact)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                className="navigation-item__icon"
                icon={faDownload}
              />
              <span className="navigation-item__text">
                App for Tellers
                <br /> {this.state.appRelease?.tag}_
                {this.state.appRelease?.releaseDate}
              </span>
              <span className="navigation-item__text"></span>
            </a>
          )}
        </div>
      </div>
    );
  }
}

export default SideNavbar = withRouter(SideNavbar);
