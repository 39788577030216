import React, { Component } from "react";

import { observer, inject } from "mobx-react";
import { getChildType } from "mobx-state-tree";

import Details from "./../../../../components/cards/details";
import Form from "./../../../../components/cards/form";

import NotFoundPage from "../../../others/not-found-page";
import Table from "../../../../components/cards/table";
import { Transactions } from "../../../../data/models/transaction";
import withAuthorization from "../../../../components/authorization";
import Loading from "../../../../components/loading";

const transactionsStore = Transactions.create({
  count: 0,
  result: [],
  next: null,
  previous: null,
});

@inject("appDataStore")
@inject("tripSchedulesStore")
@observer
@withAuthorization({
  allowedRoles: ["cash_services_admin", "cash_unit_admin"],
  permissions: ["view_tripschedule"],
})
class TripSchedulePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tripSchedule: undefined,
      isEditMode: false,
    };
  }
  async componentDidMount() {
    const { tripSchedulesStore } = this.props;
    const { tripScheduleId } = this.props.match.params;
    const response = await tripSchedulesStore.retrieve(tripScheduleId);
    if (response) {
      const tripSchedule = getChildType(tripSchedulesStore.results).create(
        response.data
      );
      this.setState({ tripSchedule });
    } else {
      this.setState({ tripSchedule: null });
    }
  }

  toggleEditMode = () => {
    const { isEditMode } = this.state;
    this.setState({ isEditMode: !isEditMode });
  };

  render() {
    const { tripSchedulesStore, appDataStore } = this.props;
    const { tripSchedule, isEditMode } = this.state;

    const { currentUser } = appDataStore;
    const { canChange, canDelete } = currentUser.getPermissions("tripschedule");

    const { canChangeState } = currentUser.getFieldPermission(
      "tripschedule",
      "state"
    );

    if (tripSchedule === undefined) {
      return <Loading />;
    } else if (tripSchedule === null) {
      return <NotFoundPage />;
    }

    return (
      <div className="animated fadeIn">
        <div className="row">
          <div className="col-8">
            <div className="card-container w-100">
              {!isEditMode ? (
                <Details
                  data={tripSchedule}
                  isLoading={tripSchedulesStore.isLoading}
                  headerTitle="TRIP SCHEDULE DETAILS"
                  onEdit={this.toggleEditMode}
                  deletable={canDelete}
                  editable={canChange || canChangeState}
                />
              ) : (
                <Form
                  data={tripSchedule}
                  headerTitle="EDIT TRIP SCHEDULE DETAILS"
                  onClose={this.toggleEditMode}
                />
              )}
            </div>
          </div>
        </div>

        {/* Display objects related to this cash unit. */}
        <div>
          {tripSchedule && (
            <div className="mt-4">
              <div className="p-3">
                <h4>Transactions</h4>
              </div>
              <Table
                {...this.props}
                store={transactionsStore}
                filter={{ trip_schedule: tripSchedule.id }}
                objectKeys={["currency", "cashIn", "cashOut", "changeFund"]}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default TripSchedulePreview;
