import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class NotFoundPage extends Component {
  render() {
    return (
      <div className="main-view-layout">
        <div className="nav-content">
          <div className="animated fadeIn">
            <div>
              <h1>Page not found.</h1>
              <Link to="/">
                <button type="button" className="btn btn-outline-dark">
                  Go to homepage
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
