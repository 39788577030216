import React, { Component } from "react";
import Table from "../../../../components/cards/table";

import { observer, inject } from "mobx-react";

import {
  CashUnitPersonnelFields,
  CashUnitPersonnelFilters,
} from "../../../../data/models/cash-unit-personnel";
import FormDrawer from "../../../../components/form-drawer";
import Form from "../../../../components/cards/form";

const ROUTE_URL = "/config/cash-unit-personnels/";

@inject("cashUnitPersonnelsStore")
@inject("appDataStore")
@observer
class CashUnitPersonnels extends Component {
  showNewlyAdded = (item) => {
    window.location.assign(`${ROUTE_URL}${item.id}`);
  };
  render() {
    const { cashUnitPersonnelsStore, appDataStore } = this.props;
    const { currentUser } = appDataStore;
    const { canAdd, canView } = currentUser.getPermissions("cashunitpersonnel");

    return (
      cashUnitPersonnelsStore && (
        <div className="animated fadeIn">
          <div className="d-flex justify-content-between">
            <div className="config-breadcrumbs ">
              <span className="active">Cash Unit Personnels</span>
            </div>
            {canAdd && (
              <FormDrawer
                title="Add Cash Unit Personnel"
                triggerButton={
                  <button
                    className="primary-color-btn"
                    data-testid="form-submit-item"
                  >
                    Add Cash Unit Personnel
                  </button>
                }
                submitButton={
                  <button
                    type="button"
                    className="primary-color-btn"
                    data-testid="form-submit-item"
                    onClick={async () => await this.submitHandler()}
                  >
                    Submit
                  </button>
                }
              >
                <Form
                  mode="form"
                  data={CashUnitPersonnelFields.create({
                    firstName: "",
                    lastName: "",
                    employeeId: "",
                    nickname: "",
                    user: null,
                    cashUnit: null,
                    isTeller: false,
                    isGeofencingActive: false,
                  })}
                  onSubmit={this.showNewlyAdded}
                  submitHandler={(submitHandler) => {
                    this.submitHandler = submitHandler;
                  }}
                />
              </FormDrawer>
            )}
          </div>

          <Table
            {...this.props}
            store={cashUnitPersonnelsStore}
            filterFields={CashUnitPersonnelFilters.create({
              isActive: "",
              isGeofencingActive: "",
              isTeller: "",
            })}
            objectKeys={[
              "firstName",
              "lastName",
              "nickname",
              "cashUnitIdentifier",
            ]}
            linkToPath={ROUTE_URL}
            canView={canView}
          />
        </div>
      )
    );
  }
}

export default CashUnitPersonnels;
