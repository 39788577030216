import React, { Component } from "react";
import PropTypes from "prop-types";

class ErrorBoundary extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      error: null,
      info: null,
    };
  }

  componentDidCatch(error, info) {
    const { onError } = this.props;

    if (typeof onError === "function") {
      try {
        onError(error, info ? info.componentStack : "");
      } catch (error) {}
    }

    this.setState({ error, info });
  }

  render() {
    const { children, FallbackComponent } = this.props;
    const { error, info } = this.state;

    if (error !== null) {
      return (
        <FallbackComponent
          componentStack={info ? info.componentStack : ""}
          error={error}
        />
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
  FallbackComponent: PropTypes.any.isRequired,
  onError: PropTypes.func,
};

export default ErrorBoundary;
