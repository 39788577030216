import $ from "jquery";
import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withMsal } from "@azure/msal-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSpinner } from "@fortawesome/free-solid-svg-icons";

import { userLogout } from "./../../api/account";

@withMsal
@inject("appDataStore")
@observer
class LogoutModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      hasError: false,
    };
  }

  componentDidMount() {
    $(`#${this.props.id}`).on("hidden.bs.modal", () => {
      this.setState({
        hasError: false,
      });
    });
  }

  logout() {
    const { appDataStore } = this.props;
    const msalInstance = this.props.msalContext.instance;
    this.setState(
      {
        isLoading: true,
        hasError: false,
      },
      () => {
        userLogout(async (response) => {
          if (
            typeof appDataStore.accessToken === "string" &&
            appDataStore.accessToken.startsWith("Bearer ")
          ) {
            await msalInstance.logoutPopup();
          }
          localStorage.clear();
          setTimeout(() => {
            window.location.assign("/");
          }, 50);
        });
      }
    );
  }

  render() {
    return (
      <div
        className="modal fade"
        id={this.props.id}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="logoutModal"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-sm box-shadow--light"
          role="document"
        >
          <div className="modal-content">
            <div className="header text-right">
              {/* <span>Confirm Logout</span> */}
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                disabled={this.state.isLoading}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <div className="body text-center">Log out now?</div>
            <div className="footer text-center">
              <button
                type="button"
                className="error-color-btn"
                onClick={() => this.logout()}
                disabled={this.state.isLoading}
              >
                {this.state.isLoading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  "LOG OUT"
                )}
              </button>
              <button
                type="button"
                className="secondary-color-btn"
                data-dismiss="modal"
                disabled={this.state.isLoading}
              >
                CANCEL
              </button>
              <div
                className={`${
                  this.state.hasError ? "d-block" : "d-none"
                } login-error-text`}
              >
                <br />
                Something went wrong. Try again later.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LogoutModal;
