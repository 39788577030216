import React, { Component } from "react";
import { TruckProviderFields } from "../../../../data/models/truck-provider";
import Table from "../../../../components/cards/table";

import { observer, inject } from "mobx-react";
import FormDrawer from "../../../../components/form-drawer";
import Form from "../../../../components/cards/form";

const ROUTE_URL = "/config/truck-providers/";

@inject("truckProvidersStore")
@inject("appDataStore")
@observer
class TruckProviders extends Component {
  showNewlyAdded = (item) => {
    window.location.assign(`${ROUTE_URL}${item.id}`);
  };

  render() {
    const { truckProvidersStore, appDataStore } = this.props;
    const { currentUser } = appDataStore;
    const { canAdd, canView } = currentUser.getPermissions("truckprovider");

    return (
      <div>
        <div className="d-flex justify-content-between">
          <div className="config-breadcrumbs ">
            <span className="active">Truck Providers</span>
          </div>
          {canAdd && (
            <FormDrawer
              title="Add Truck Provider"
              triggerButton={
                <button
                  className="primary-color-btn"
                  data-testid="form-submit-item"
                >
                  Add Truck Provider
                </button>
              }
              submitButton={
                <button
                  type="button"
                  className="primary-color-btn"
                  data-testid="form-submit-item"
                  onClick={async () => await this.submitHandler()}
                >
                  Submit
                </button>
              }
            >
              <Form
                mode="form"
                data={TruckProviderFields.create({
                  name: "",
                })}
                onSubmit={this.showNewlyAdded}
                submitHandler={(submitHandler) => {
                  this.submitHandler = submitHandler;
                }}
              />
            </FormDrawer>
          )}
        </div>

        <Table
          {...this.props}
          store={truckProvidersStore}
          objectKeys={["name"]}
          linkToPath={ROUTE_URL}
          canView={canView}
        />
      </div>
    );
  }
}

export default TruckProviders;
