import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import ConfigNavbar from "./../../../components/navbar/config-navbar";

import CashUnits from "./cash-units";
import CashUnitPreview from "./cash-units/preview";

import Groups from "./groups";
import GroupPreview from "./groups/preview";

import ClientAddresses from "./client-addresses";
import ClientAddressPreview from "./client-addresses/preview";

import Schedules from "./schedules";
import SchedulePreview from "./schedules/preview";

import CashUnitPersonnels from "./cash-unit-personnels";
import CashUnitPersonnelPreview from "./cash-unit-personnels/preview";

import Trucks from "./trucks";
import TruckPreview from "./trucks/preview";

import TruckProviders from "./truck-providers";
import TruckProviderPreview from "./truck-providers/preview";

import TruckPersonnels from "./truck-personnels";
import TruckPersonnelPreview from "./truck-personnels/preview";

import Trips from "./trips";
import TripPreview from "./trips/preview";

import TripSchedules from "./trip-schedules";
import TripSchedulePreview from "./trip-schedules/preview";

import AddressGeocodes from "./address-geocodes";
import AddressGeocodePreview from "./address-geocodes/preview";

import ExtractTransactionDataRequests from "./transaction-data-requests";
import ExtractTransactionDataRequestPreview from "./transaction-data-requests/preview";

export default class Config extends Component {
  render() {
    return (
      <div className="p-0 config-container container-fluid animated fadeIn">
        <div className="row no-gutters">
          <div className="col-md-4 col-lg-3 col-xl-2">
            <ConfigNavbar />
          </div>
          <div className="config-content col-md-8 col-lg-9 col-xl-10">
            <Switch>
              <Route
                path="/config/cashunits/:cashUnitId"
                component={CashUnitPreview}
              />
              <Route path="/config/cashunits" component={CashUnits} />
              <Route path="/config/groups/:groupId" component={GroupPreview} />
              <Route path="/config/groups" component={Groups} />
              <Route
                path="/config/client-addresses/:clientAddressId"
                component={ClientAddressPreview}
              />
              <Route
                path="/config/client-addresses"
                component={ClientAddresses}
              />

              <Route
                path="/config/schedules/:scheduleId"
                component={SchedulePreview}
              />
              <Route path="/config/schedules" component={Schedules} />

              <Route
                path="/config/cash-unit-personnels/:cashUnitPersonnelId"
                component={CashUnitPersonnelPreview}
              />
              <Route
                path="/config/cash-unit-personnels"
                component={CashUnitPersonnels}
              />

              <Route path="/config/trucks/:truckId" component={TruckPreview} />
              <Route path="/config/trucks" component={Trucks} />

              <Route
                path="/config/truck-providers/:truckProviderId"
                component={TruckProviderPreview}
              />
              <Route
                path="/config/truck-providers"
                component={TruckProviders}
              />

              <Route
                path="/config/truck-personnels/:truckPersonnelId"
                component={TruckPersonnelPreview}
              />
              <Route
                path="/config/truck-personnels"
                component={TruckPersonnels}
              />
              <Route path="/config/trips/:tripId" component={TripPreview} />
              <Route path="/config/trips/" component={Trips} />

              <Route
                path="/config/trip-schedules/:tripScheduleId"
                component={TripSchedulePreview}
              />
              <Route path="/config/trip-schedules/" component={TripSchedules} />

              <Route
                path="/config/address-geocodes/:addressGeocodeId"
                component={AddressGeocodePreview}
              />
              <Route
                path="/config/address-geocodes/"
                component={AddressGeocodes}
              />

              <Route
                path="/config/transaction-data-requests/:extractTransactionDataRequestId"
                component={ExtractTransactionDataRequestPreview}
              />
              <Route
                path="/config/transaction-data-requests/"
                component={ExtractTransactionDataRequests}
              />

              <Route>
                <Redirect to="/config/cashunits" />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}
