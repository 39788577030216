import React from "react";
import PropTypes from "prop-types";

class Websocket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ws: new WebSocket(this.props.url, this.props.protocol),
      attempts: 1,
    };
  }

  generateInterval(k) {
    if (this.props.reconnectIntervalInMilliSeconds > 0) {
      return this.props.reconnectIntervalInMilliSeconds;
    }
    return Math.min(30, Math.pow(2, k) - 1) * 1000;
  }

  setupWebsocket() {
    const { ws } = this.state;
    const { reconnect } = this.props;

    ws.onopen = () => {
      console.log("Websocket connected");
      if (this.props.onOpen) {
        this.props.onOpen();
      }
    };

    ws.onerror = (e) => {
      if (this.props.onError) {
        this.props.onError(e);
      }
    };

    ws.onmessage = (evt) => {
      if (this.props.onMessage) {
        this.props.onMessage(evt.data);
      }
    };

    ws.onclose = (evt) => {
      console.log(
        `Websocket disconnected,the reason: ${evt.reason},the code: ${evt.code}`
      );
      if (typeof this.props.onClose === "function")
        this.props.onClose(evt.code, evt.reason);
      if (reconnect) {
        let time = this.generateInterval(this.state.attempts);
        this.timeoutID = setTimeout(() => {
          this.setState({ attempts: this.state.attempts + 1 });
          this.setState({
            ws: new WebSocket(this.props.url, this.props.protocol),
          });
          this.setupWebsocket();
        }, time);
      }
    };
  }

  componentDidMount() {
    this.setupWebsocket();
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
    let websocket = this.state.ws;
    websocket.close();
  }

  sendMessage = (message) => {
    let websocket = this.state.ws;
    websocket.send(message);
  };

  render() {
    return <div></div>;
  }
}

Websocket.defaultProps = {
  debug: false,
  reconnect: true,
};

Websocket.propTypes = {
  url: PropTypes.string.isRequired,
  onMessage: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onError: PropTypes.func,
  debug: PropTypes.bool,
  reconnect: PropTypes.bool,
  protocol: PropTypes.string,
  reconnectIntervalInMilliSeconds: PropTypes.number,
};

export default Websocket;
