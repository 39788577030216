import { request, requestWithToken } from "./index";

export function getLatestAppRelease(callback) {
  requestWithToken
    .get("latest-app-release")
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      callback(error.response);
    });
}
