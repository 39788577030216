import * as React from "react";
import ErrorBoundary from "../components/error-boundary";
import { Provider } from "mobx-react";

import {
  addressGeocodesStore,
  cashUnitsStore,
  clientAddressesStore,
  schedulesStore,
  cashUnitPersonnelsStore,
  trucksStore,
  truckProvidersStore,
  truckPersonnelsStore,
  usersStore,
  cifIdsStore,
  masterCifsStore,
  groupsStore,
  appDataStore,
  tripsStore,
  prePlannedTripsStore,
  prePlannedTripSchedulesStore,
  tripSchedulesStore,
  extractTransactionDataRequestsStore,
  cashUnitConfigurationsStore,
} from "../data/stores";

import * as store from "../data/stores";

const ErrorFallback = () => {
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
      <button
        className="mt-4"
        onClick={() => {
          localStorage.clearToken();
          window.location.assign(window.location.origin);
        }}
      >
        Refresh
      </button>
    </div>
  );
};

export const AppProvider = ({ children }) => {
  return (
    <React.Suspense
      fallback={
        <div className="flex items-center justify-center w-screen h-screen"></div>
      }
    >
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(details) => {}}
      >
        <Provider
          appDataStore={appDataStore}
          cashUnitsStore={cashUnitsStore}
          clientAddressesStore={clientAddressesStore}
          schedulesStore={schedulesStore}
          cashUnitPersonnelsStore={cashUnitPersonnelsStore}
          trucksStore={trucksStore}
          truckProvidersStore={truckProvidersStore}
          truckPersonnelsStore={truckPersonnelsStore}
          usersStore={usersStore}
          cifIdsStore={cifIdsStore}
          masterCifsStore={masterCifsStore}
          groupsStore={groupsStore}
          tripsStore={tripsStore}
          prePlannedTripsStore={prePlannedTripsStore}
          prePlannedTripSchedulesStore={prePlannedTripSchedulesStore}
          tripSchedulesStore={tripSchedulesStore}
          addressGeocodesStore={addressGeocodesStore}
          extractTransactionDataRequestsStore={
            extractTransactionDataRequestsStore
          }
          cashUnitConfigurationsStore={cashUnitConfigurationsStore}
          store={store}
        >
          {children}
        </Provider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
