import {
  CashUnitByIdReference,
  CashUnit,
  CashUnits,
} from "../models/cash-unit";
import { ClientAddresses } from "../models/client-address";
import { Schedules } from "../models/schedule";
import { CashUnitPersonnels } from "../models/cash-unit-personnel";
import { TruckByIdReference, Trucks, Truck } from "../models/truck";
import { TruckProviders } from "../models/truck-provider";
import { TruckPersonnels } from "../models/truck-personnel";
import { Users, User } from "../models";
import { CifIds } from "../models/cif-id";
import { MasterCifs } from "../models/master-cif";
import { PrePlannedTrips } from "../models/pre-planned-trip";
import { PrePlannedTripSchedules } from "../models/pre-planned-trip-schedule";
import { Groups } from "../models/group";
import { Trips } from "../models/trip";
import { flow, types } from "mobx-state-tree";
import { requestWithToken } from "../../api";
import { TripSchedules } from "../models/trip-schedule";
import { AddressGeocodes } from "../models/address-geocode";
import { ExtractTransactionDataRequests } from "../models/extract-transaction-data-request";
import { CashUnitConfigurations } from "../models/cash-unit-configuration";
import { ActionMixin } from "../models/mixins";

const cashUnitsStore = CashUnits.create({
  count: 0,
  results: [],
  next: null,
  previous: null,
});

const clientAddressesStore = ClientAddresses.create({
  count: 0,
  results: [],
  next: null,
  previous: null,
});

const schedulesStore = Schedules.create({
  count: 0,
  results: [],
  next: null,
  previous: null,
});

const cashUnitPersonnelsStore = CashUnitPersonnels.create({
  count: 0,
  results: [],
  next: null,
  previous: null,
});

const trucksStore = Trucks.create({
  count: 0,
  results: [],
  next: null,
  previous: null,
});

const truckProvidersStore = TruckProviders.create({
  count: 0,
  results: [],
  next: null,
  previous: null,
});

const truckPersonnelsStore = TruckPersonnels.create({
  count: 0,
  results: [],
  next: null,
  previous: null,
});

const usersStore = Users.create({
  count: 0,
  results: [],
  next: null,
  previous: null,
});

const cifIdsStore = CifIds.create({
  count: 0,
  results: [],
  next: null,
  previous: null,
});

const masterCifsStore = MasterCifs.create({
  count: 0,
  results: [],
  next: null,
  previous: null,
});

const groupsStore = Groups.create({
  count: 0,
  results: [],
  next: null,
  previous: null,
});

const tripsStore = Trips.create({
  count: 0,
  results: [],
  next: null,
  previous: null,
});

const tripSchedulesStore = TripSchedules.create({
  count: 0,
  results: [],
  next: null,
  previous: null,
});

const addressGeocodesStore = AddressGeocodes.create({
  count: 0,
  results: [],
  next: null,
  previous: null,
});

const prePlannedTripsStore = PrePlannedTrips.create({
  count: 0,
  results: [],
  next: null,
  previous: null,
});

const prePlannedTripSchedulesStore = PrePlannedTripSchedules.create({
  count: 0,
  results: [],
  next: null,
  previous: null,
});

const extractTransactionDataRequestsStore =
  ExtractTransactionDataRequests.create({
    count: 0,
    results: [],
    next: null,
    previous: null,
  });

const cashUnitConfigurationsStore = CashUnitConfigurations.create({
  count: 0,
  results: [],
  next: null,
  previous: null,
});

const AppDataStore = types
  .model("AppDataStore", {
    currentUser: types.maybeNull(User),
    accessToken: types.maybeNull(types.string),
  })
  .actions((self) => ({
    getCurrentUser: flow(function* getCurrentUser() {
      let response;
      try {
        response = yield requestWithToken.get("users/me");
        self.currentUser = User.create(response.data);
      } catch (error) {
        response = error.response;
      }
      return response;
    }),
    setAccessToken(value) {
      self.accessToken = value;
    },
  }));

const appDataStore = AppDataStore.create({ currentUserStore: null });

const DashboardFilter = types
  .compose(
    ActionMixin,
    types.model({
      cashUnit: types.union(
        CashUnitByIdReference,
        CashUnit,
        types.integer,
        types.null
      ),
      truck: types.union(TruckByIdReference, Truck, types.integer, types.null),
    })
  )
  .actions((self) => ({
    setValue: ({ key, value }) => {
      if (key === "cashUnit" && value !== null && self.value !== value) {
        self.truck = null;
      }
      self[key] = value;
    },
  }))
  .volatile((self) => ({
    isLoading: false,
  }));

export {
  cashUnitsStore,
  clientAddressesStore,
  schedulesStore,
  cashUnitPersonnelsStore,
  trucksStore,
  truckProvidersStore,
  truckPersonnelsStore,
  usersStore,
  cifIdsStore,
  masterCifsStore,
  groupsStore,
  tripsStore,
  tripSchedulesStore,
  addressGeocodesStore,
  appDataStore,
  prePlannedTripsStore,
  prePlannedTripSchedulesStore,
  extractTransactionDataRequestsStore,
  cashUnitConfigurationsStore,
  DashboardFilter,
};
