import React, { Component } from "react";

import { observer, inject } from "mobx-react";
import { getChildType } from "mobx-state-tree";

import Details from "./../../../../components/cards/details";
import Form from "./../../../../components/cards/form";
import NotFoundPage from "../../../others/not-found-page";
import withAuthorization from "../../../../components/authorization";

import { CashUnits } from "../../../../data/models/cash-unit";

import { asyncSelectSearch } from "../../../../utils";

const cashUnitsStore = CashUnits.create({
  count: 0,
  next: null,
  previous: null,
  results: [],
});

@inject("appDataStore")
@inject("truckPersonnelsStore")
@observer
@withAuthorization({
  allowedRoles: ["cash_services_admin", "cash_unit_admin"],
  permissions: ["view_truckpersonnel"],
})
class TruckPersonnelPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      truckPersonnel: null,
      isEditMode: false,
    };
  }
  async componentDidMount() {
    const { truckPersonnelsStore } = this.props;
    const { truckPersonnelId } = this.props.match.params;
    const response = await truckPersonnelsStore.retrieve(truckPersonnelId);
    const truckPersonnel = getChildType(truckPersonnelsStore.results).create(
      response.data
    );
    this.setState({ truckPersonnel });
  }

  toggleEditMode = () => {
    const { isEditMode } = this.state;
    this.setState({ isEditMode: !isEditMode });
  };

  render() {
    const { truckPersonnelsStore, appDataStore } = this.props;
    const { truckPersonnel, isEditMode } = this.state;

    const { currentUser } = appDataStore;
    const { canChange, canDelete } = currentUser.getPermissions("truck");

    if (typeof truckPersonnel === "undefined") {
      return <NotFoundPage />;
    }

    return (
      <div className="animated fadeIn">
        <div className="row">
          <div className="col-8">
            <div className="card-container w-100">
              {!isEditMode && (
                <Details
                  data={truckPersonnel}
                  isLoading={truckPersonnelsStore.isLoading}
                  headerTitle="TRUCK PERSONNEL DETAILS"
                  onEdit={this.toggleEditMode}
                  editable={canChange}
                  deletable={canDelete}
                />
              )}
              {isEditMode && (
                <Form
                  data={truckPersonnel}
                  headerTitle="EDIT TRUCK PERSONNEL DETAILS"
                  onClose={this.toggleEditMode}
                  selectSearch={{
                    cashUnit: asyncSelectSearch({
                      store: cashUnitsStore,
                      labelKey: "cashUnitIdentifier",
                      valueKey: "id",
                    }),
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="row">
          {/* Display objects related to this cash unit. */}
        </div>
      </div>
    );
  }
}

export default TruckPersonnelPreview;
