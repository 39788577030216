import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

import LogoutModal from "./../modal/logout-modal";
import { inject, observer } from "mobx-react";

@inject("appDataStore")
@observer
class NavBarTop extends Component {
  render() {
    const { appDataStore } = this.props;
    return (
      <div>
        <div className="navbar-top box-shadow--light">
          <div className="title font-weight-bold">CashMob Web Portal</div>
          <div className="account">
            <button
              type="button"
              className="d-inline-block"
              data-toggle="dropdown"
            >
              {appDataStore.currentUser && (
                <span className="d-inline-flex flex-column">
                  <b>{appDataStore.currentUser.username}</b>
                  <span className="badge badge-dark">
                    {appDataStore.currentUser.permissionGroups.join(" | ")}
                  </span>
                </span>
              )}
              <FontAwesomeIcon icon={faCaretDown} />
            </button>
            <div className="dropdown-menu">
              <span
                className="dropdown-item"
                data-toggle="modal"
                data-target="#logout-modal"
              >
                Log out
              </span>
            </div>
          </div>
        </div>
        <LogoutModal id="logout-modal" />
      </div>
    );
  }
}

export default NavBarTop;
