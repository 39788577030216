import { types } from "mobx-state-tree";

import { BaseModel, Pagination, BaseFilters } from ".";

import { CreateModelMixin, RetrieveModelMixin, ActionMixin } from "./mixins";

import { ScheduleDayOfWeek } from "./schedule";
import { CashUnit, CashUnitByIdReference } from "./cash-unit";
import { requestWithToken } from "../../api";

const PrePlannedTripFilters = types.compose(
  ActionMixin,
  BaseFilters,
  types.model({
    cashUnit: types.maybeNull(CashUnitByIdReference),
  })
);

const PrePlannedTripFields = types.compose(
  CreateModelMixin,
  RetrieveModelMixin,
  types
    .model({
      cashUnit: types.union(
        CashUnitByIdReference,
        CashUnit,
        types.integer,
        types.null
      ),
      cashUnitIdentifier: types.string,
      dayOfWeek: types.union(ScheduleDayOfWeek, types.literal("")),
      distance: types.number,
      combination: types.string,
    })
    .views((self) => ({
      get display() {
        return `${self.dayOfWeek} - ${self.distance}`;
      },
    }))
);

const PrePlannedTrip = types.compose(BaseModel, PrePlannedTripFields);

const PrePlannedTrips = types
  .compose(Pagination, types.model({ results: types.array(PrePlannedTrip) }))
  .volatile((self) => ({
    endpoint: "pre-planned-trips",
  }));

const PrePlannedTripByIdReference = types.reference(PrePlannedTrip, {
  async get(value) {
    const response = await requestWithToken.get(`pre-planned-trips/${value}/`);
    let obj = PrePlannedTrip.create(response.data);
    return obj;
  },
  set(value) {
    return value;
  },
});

export {
  PrePlannedTripFilters,
  PrePlannedTripFields,
  PrePlannedTrip,
  PrePlannedTrips,
  PrePlannedTripByIdReference,
};
