import { request, requestWithToken } from "./index";

export function userCreate(data, callback) {
  request
    .post("users/", data)
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      callback(error.response);
    });
}

/*
    User Login
*/
export function userLogin(credentials, callback) {
  request
    .post("auth/token/login/", {
      username: credentials.username,
      password: credentials.password,
    })
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      callback(error.response);
    });
}

/*
    User Logout
*/
export function userLogout(callback) {
  requestWithToken
    .post("auth/token/logout/")
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      callback(error);
    });
}

/*
    Current User Info
*/
export function getMyAccountInfo(callback) {
  requestWithToken
    .get("users/me/")
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      callback(error.response);
    });
}

export function resendActivation(data, callback) {
  request
    .post("users/resend_activation/", data)
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      callback(error.response);
    });
}

export function activation(data, callback) {
  request
    .post("users/activation/", data)
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      callback(error.response);
    });
}

export function resetPassword(data, callback) {
  request
    .post("users/reset_password/", data)
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      callback(error.response);
    });
}

export function resetPasswordConfirm(data, callback) {
  request
    .post("users/reset_password_confirm/", data)
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      callback(error.response);
    });
}
