import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withMsal } from "@azure/msal-react";

import { userLogin } from "../../api/account";
import LoginPage from "./login";
import SignupPage from "./signup";
import PasswordResetPage from "./password-reset";
import UserActivationPage from "./user-activation";
import UserResendActivationPage from "./user-resend-activation";
import PasswordResetConfirmPage from "./password-reset-confirm";
import Navigation from "./navigation";
import { ALLOW_USER_CREATION } from "../../data/constants";

class Authentication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      hasError: false,
      isLoading: false,
    };
  }

  handleChange(state, value) {
    this.setState({
      [state]: value,
      hasError: false,
    });
  }

  login(e) {
    e.preventDefault();

    this.setState(
      {
        isLoading: true,
        hasError: false,
      },
      () => {
        userLogin(
          {
            username: this.state.username,
            password: this.state.password,
          },
          (response) => {
            if (response.status === 200) {
              // handle success
              localStorage.setItem(
                "CM_AUTH",
                "Token " + response.data.authToken
              );
              setTimeout(() => {
                window.location.reload();
              }, 50);
            } else {
              // handle error
              console.log("Access denied");
              this.setState({
                hasError: true,
                isLoading: false,
              });
            }
          }
        );
      }
    );
  }

  render() {
    return (
      <div className="login-view-layout">
        <div className="login-view-overlay">
          <div className="container">
            <div className="row vh-100 justify-content-center align-items-center">
              <div className="text-center">
                <Switch>
                  <Route path="/login" component={LoginPage} />
                  {ALLOW_USER_CREATION && (
                    <>
                      <Route path="/signup" component={SignupPage} />
                      <Route
                        path="/password-reset"
                        component={PasswordResetPage}
                      />
                      <Route
                        path="/password-reset-confirm"
                        component={PasswordResetConfirmPage}
                      />
                      <Route
                        path="/activation"
                        component={UserActivationPage}
                      />
                      <Route
                        path="/resend-activation"
                        component={UserResendActivationPage}
                      />
                    </>
                  )}

                  <Route>
                    <Redirect to="/login" />
                  </Route>
                </Switch>
                <div className="mt-5">
                  <Navigation />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const AuthenticationView = withMsal(Authentication);
export default AuthenticationView;
