import React, { Component } from "react";

import alertify from "alertifyjs";
import _ from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import Logo from "../../components/logo";
import { resetPasswordConfirm } from "../../api/account";

export default class PasswordResetConfirmPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      uid: "",
      newPassword: "",
      reNewPassword: "",
      hasError: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    const url = window.location.href;
    const urlParams = new URLSearchParams(url.split("?")[1]);

    const uid = urlParams.get("uid");
    const token = urlParams.get("token");

    this.setState({ uid, token });
  }

  handleChange = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    const { token, uid, newPassword, reNewPassword } = this.state;
    this.setState({
      isLoading: true,
    });
    resetPasswordConfirm(
      {
        token,
        uid,
        newPassword,
        reNewPassword,
      },
      (response) => {
        if (response.status >= 200 && response.status < 300) {
          alertify.success("Password reset successful.");
          this.setState({
            newPassword: "",
            reNewPassword: "",
          });
        } else if (response.status >= 400 && response.status < 500) {
          alertify.error(
            Object.entries(response.data)
              .map(([key, value]) => {
                return `${_.startCase(key).toUpperCase()} - ${value.join()}`;
              })
              .join()
          );
        } else {
          alertify.error(response.statusText);
        }
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  render() {
    const { newPassword, reNewPassword, isLoading } = this.state;
    return (
      <div className="login-container box-shadow--dark">
        <div className="login-header">
          <div className="d-inline-block login-logo">
            <Logo width="40" height="47" />
          </div>
          <div className="d-inline-block login-title">
            <span>CashMob</span>
            <span className="subtitle">P O R T A L</span>
          </div>
        </div>
        <div className="login-body">
          <form onSubmit={this.handleSubmit}>
            <input
              type="password"
              className="login-input-field"
              value={newPassword}
              name="newPassword"
              onChange={this.handleChange}
              placeholder="New password"
              disabled={isLoading}
              required
            />
            <input
              type="password"
              className="login-input-field"
              value={reNewPassword}
              name="reNewPassword"
              onChange={this.handleChange}
              placeholder="Confirm new password"
              disabled={isLoading}
              required
            />
            <br />
            <button
              type="submit"
              className="primary-color-btn login-submit-button"
              disabled={this.state.isLoading}
            >
              {this.state.isLoading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                "Reset Password"
              )}
            </button>
          </form>
        </div>
      </div>
    );
  }
}
