import React, { Component } from "react";
import { withMsal } from "@azure/msal-react";

import alertify from "alertifyjs";
import _ from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import Logo from "../../components/logo";
import LogoAdi from "../../components/logo-adi";

import { userLogin } from "../../api/account";
import { accessTokenRequest } from "../../msalConfig";
import { observer, inject } from "mobx-react";

@withMsal
@inject("appDataStore")
@observer
class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      isLoading: false,
    };
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  login = (e) => {
    e.preventDefault();
    const { appDataStore } = this.props;

    this.setState({
      isLoading: true,
    });
    userLogin(
      {
        username: this.state.username,
        password: this.state.password,
      },
      (response) => {
        if (response.status >= 200 && response.status < 300) {
          alertify.success("User successfully authenticated.");
          const token = "Token " + response.data.authToken;
          localStorage.setItem("CM_AUTH", token);
          appDataStore.setAccessToken(token);
          setTimeout(() => {
            window.location.reload();
          }, 50);
        } else if (response.status >= 400 && response.status < 500) {
          alertify.error(
            Object.entries(response.data)
              .map(([key, value]) => {
                return `${_.startCase(key).toUpperCase()} - ${value.join()}`;
              })
              .join()
          );
        }
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  render() {
    const { appDataStore } = this.props;
    const msalInstance = this.props.msalContext.instance;

    return (
      <div className="login-container box-shadow--dark">
        <div className="login-header">
          <div className="d-inline-block login-logo">
            <Logo width="40" height="47" />
          </div>
          <div className="d-inline-block login-title">
            <span>CashMob</span>
            <span className="subtitle">P O R T A L</span>
          </div>
        </div>
        <div className="login-body">
          <form onSubmit={this.login}>
            <input
              type="text"
              className="login-input-field"
              value={this.state.username}
              name="username"
              onChange={this.handleChange}
              placeholder="Username"
              disabled={this.state.isLoading}
              required
            />
            <input
              type="password"
              className="login-input-field"
              value={this.state.password}
              name="password"
              onChange={this.handleChange}
              placeholder="Password"
              disabled={this.state.isLoading}
              required
            />
            <br />
            <button
              type="submit"
              className="primary-color-btn login-submit-button"
              disabled={this.state.isLoading}
            >
              {this.state.isLoading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                "LOGIN"
              )}
            </button>
            <hr />
            <button
              type="button"
              className="secondary-color-btn login-submit-button"
              onClick={() => {
                msalInstance
                  .loginPopup(accessTokenRequest)
                  .then((resp) => {
                    const token = "Bearer " + resp.accessToken;
                    localStorage.setItem("CM_AUTH", token);
                    appDataStore.setAccessToken(token);
                    setTimeout(() => {
                      window.location.assign("/");
                    }, 50);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              Login using Microsoft O365
            </button>
          </form>
          <div className="mt-4 opacity-2">
            <LogoAdi />
          </div>
        </div>
      </div>
    );
  }
}

export default LoginPage;
