import React, { Component } from "react";

import Table from "../../../../components/cards/table";
import { observer, inject } from "mobx-react";
import {
  ClientAddressFields,
  ClientAddressFilters,
} from "../../../../data/models/client-address";
import FormDrawer from "../../../../components/form-drawer";
import Form from "../../../../components/cards/form";

const ROUTE_URL = "/config/truck-providers/";

@inject("clientAddressesStore")
@inject("appDataStore")
@observer
class ClientAddresses extends Component {
  showNewlyAdded = (item) => {
    window.location.assign(`${ROUTE_URL}${item.id}`);
  };

  render() {
    const { clientAddressesStore, appDataStore } = this.props;
    const { currentUser } = appDataStore;
    const { canAdd, canView } = currentUser.getPermissions("clientaddress");

    return (
      <div className="animated fadeIn">
        <div className="d-flex justify-content-between">
          <div className="config-breadcrumbs ">
            <span className="active">Client Addresses</span>
          </div>
          {canAdd && (
            <FormDrawer
              title="Add Client Address"
              triggerButton={
                <button
                  className="primary-color-btn"
                  data-testid="form-submit-item"
                >
                  Add Client Address
                </button>
              }
              submitButton={
                <button
                  type="button"
                  className="primary-color-btn"
                  data-testid="form-submit-item"
                  onClick={async () => await this.submitHandler()}
                >
                  Submit
                </button>
              }
            >
              <Form
                mode="form"
                data={ClientAddressFields.create({
                  name: "",
                  address: "",
                  accountNumber: "",
                  cashUnit: null,
                  type: "",
                  cifId: null,
                  groupName: "",
                })}
                onSubmit={this.showNewlyAdded}
                submitHandler={(submitHandler) => {
                  this.submitHandler = submitHandler;
                }}
              />
            </FormDrawer>
          )}
        </div>

        <Table
          {...this.props}
          store={clientAddressesStore}
          filterFields={ClientAddressFilters.create({
            isActive: "",
            cashUnit: null,
            type: "",
            group: null,
          })}
          objectKeys={["name", "type", "cashUnitIdentifier", "groupName"]}
          linkToPath={ROUTE_URL}
          canView={canView}
        />
      </div>
    );
  }
}

export default ClientAddresses;
