import React from "react";

import { observer, inject } from "mobx-react";
import Table from "../../../../components/cards/table";
import { TripFilters, TripFields } from "../../../../data/models/trip";
import FormDrawer from "../../../../components/form-drawer";
import Form from "../../../../components/cards/form";
import withAuthorization from "../../../../components/authorization";

const ROUTE_URL = "/config/trips/";

@inject("tripsStore")
@inject("appDataStore")
@observer
@withAuthorization({
  allowedRoles: ["cash_services_admin", "cash_unit_admin"],
  permissions: ["view_trip"],
})
class Trips extends React.Component {
  async componentDidMount() {
    const { tripsStore } = this.props;
    await tripsStore.list();
  }
  showNewlyAdded = (item) => {
    window.location.assign(`${ROUTE_URL}${item.id}`);
  };

  render() {
    const { tripsStore, appDataStore } = this.props;
    const { currentUser } = appDataStore;
    const { canAdd, canView } = currentUser.getPermissions("trip");

    return (
      <div className="animated fadeIn">
        <div className="d-flex justify-content-between">
          <div className="config-breadcrumbs ">
            <span className="active">Trips</span>
          </div>
          {canAdd && (
            <FormDrawer
              title="Add Truck Personnel"
              triggerButton={
                <button
                  className="primary-color-btn"
                  data-testid="form-submit-item"
                >
                  Add Trip
                </button>
              }
              submitButton={
                <button
                  type="button"
                  className="primary-color-btn"
                  data-testid="form-submit-item"
                  onClick={async () => await this.submitHandler()}
                >
                  Submit
                </button>
              }
            >
              <Form
                mode="form"
                data={TripFields.create({
                  cashUnit: null,
                  truck: null,
                  teller: null,
                  driver: null,
                  escort: null,
                  teamLeader: null,
                  date: null,
                })}
                onSubmit={this.showNewlyAdded}
                submitHandler={(submitHandler) => {
                  this.submitHandler = submitHandler;
                }}
              />
            </FormDrawer>
          )}
        </div>

        <Table
          {...this.props}
          store={tripsStore}
          filterFields={TripFilters.create({ isActive: "" })}
          objectKeys={["date", "truckName", "tellerName", "cashUnitIdentifier"]}
          canView={canView}
          linkToPath={ROUTE_URL}
        />
      </div>
    );
  }
}

export default Trips;
