import React from "react";
import { types } from "mobx-state-tree";
import Table from "../../../../components/cards/table";
import { observer, inject } from "mobx-react";
import { ActionMixin } from "../../../../data/models/mixins";
import withAuthorization from "../../../../components/authorization";

const ROUTE_URL = "/users/active/";

@inject("usersStore")
@inject("appDataStore")
@observer
@withAuthorization({
  allowedRoles: ["cash_services_admin", "cash_unit_admin"],
  permissions: ["view_user"],
})
class ActiveUsers extends React.Component {
  showNewlyAdded = (item) => {
    window.location.assign(`${ROUTE_URL}${item.id}`);
  };

  render() {
    const { usersStore, appDataStore } = this.props;
    const { currentUser } = appDataStore;
    const { canView } = currentUser.getPermissions("user");

    return (
      <div className="animated fadeIn">
        <div className="d-flex justify-content-between">
          <div className="config-breadcrumbs ">
            <span className="active">Active Users</span>
          </div>
        </div>

        <Table
          {...this.props}
          store={usersStore}
          objectKeys={["username", "email"]}
          filterFields={types
            .compose(ActionMixin, types.model({ search: types.string }))
            .create({ search: "" })}
          linkToPath={ROUTE_URL}
          canView={canView}
        />
      </div>
    );
  }
}

export default ActiveUsers;
