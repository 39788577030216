import React, { Component, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { observer, inject } from "mobx-react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  withMsal,
} from "@azure/msal-react";

import { PublicClientApplication } from "@azure/msal-browser";

import { accessTokenRequest, msalConfig } from "../msalConfig";
import MainView from "./main";
import AuthenticationView from "./authentication";

import { requestWithToken, requestWithTokenNoBaseUrl } from "../api";
import Loading from "../components/loading";

export const msalInstance = new PublicClientApplication(msalConfig);

@withMsal
@inject("appDataStore")
@observer
class AppView extends Component {
  componentDidMount() {
    const { appDataStore } = this.props;
    const msalInstance = this.props.msalContext.instance;
    const account = this.props.msalContext.accounts[0] || {};

    const requestConfigHandler = async (config) => {
      let token = localStorage.getItem("CM_AUTH");

      if (typeof token === "string" && token.startsWith("Bearer ")) {
        let tokens;
        try {
          tokens = await msalInstance.acquireTokenSilent({
            accessTokenRequest,
            account: account,
          });
        } catch (err) {
          console.log(err);
        }

        if (tokens) {
          token = "Bearer " + tokens.accessToken;
          localStorage.setItem("CM_AUTH", token);
        } else {
          // Redirect to login when the refresh token expires.
          localStorage.clear();
          setTimeout(() => {
            window.location.assign("/");
          }, 50);
        }
      }

      appDataStore.setAccessToken(token);
      return config;
    };

    requestWithToken.interceptors.request.use(requestConfigHandler);
    requestWithTokenNoBaseUrl.interceptors.request.use(requestConfigHandler);
  }

  isLoggedIn = () => {
    const cmAuth = localStorage.getItem("CM_AUTH");
    if (cmAuth && typeof cmAuth === "string") {
      return cmAuth.startsWith("Token ") || cmAuth.startsWith("Bearer ");
    } else {
      return false;
    }
  };

  render() {
    return (
      <div>
        <Suspense
          fallback={
            <div className="d-flex align-items-center justify-content-center">
              <Loading />
            </div>
          }
        >
          <AuthenticatedTemplate>
            <BrowserRouter>
              <MainView />
            </BrowserRouter>
          </AuthenticatedTemplate>

          {/**
           * Fallback when logged using default authentication
           */}
          <UnauthenticatedTemplate>
            <BrowserRouter>
              {this.isLoggedIn() ? <MainView /> : <AuthenticationView />}
            </BrowserRouter>
          </UnauthenticatedTemplate>

          {/* <MsalAuthenticationTemplate interactionType={InteractionType.Popup}>
            <MainView />
          </MsalAuthenticationTemplate> */}
        </Suspense>
      </div>
    );
  }
}

export default AppView;
