import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { ALLOW_USER_CREATION } from "../../data/constants";

@withRouter
class AuthNav extends Component {
  render() {
    let path = this.props.location.pathname;
    const login_path = "/login";
    const signup_path = "/signup";
    const password_reset = "/password-reset";
    const resend_activation = "/resend-activation";
    return ALLOW_USER_CREATION ? (
      <div className="card box-shadow--dark">
        <div className="card-body">
          {!path.includes(login_path) && (
            <Link to={login_path}>
              <button type="button" className="btn btn-link primary-color-link">
                Login
              </button>
            </Link>
          )}

          {!path.includes(signup_path) && (
            <Link to={signup_path}>
              <button type="button" className="btn btn-link primary-color-link">
                Sign up
              </button>
            </Link>
          )}

          {!path.includes(password_reset) && (
            <Link to={password_reset}>
              <button type="button" className="btn btn-link primary-color-link">
                Forgot password?
              </button>
            </Link>
          )}

          {!path.includes(resend_activation) && (
            <Link to={resend_activation}>
              <button type="button" className="btn btn-link primary-color-link">
                Resend Activation
              </button>
            </Link>
          )}
        </div>
      </div>
    ) : (
      <div></div>
    );
  }
}

export default AuthNav;
