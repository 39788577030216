import React, { Component } from "react";

import { observer, inject } from "mobx-react";
import { getChildType } from "mobx-state-tree";

import Details from "./../../../../components/cards/details";
import Form from "./../../../../components/cards/form";

import NotFoundPage from "../../../others/not-found-page";
import Table from "../../../../components/cards/table";
import { TripSchedules } from "../../../../data/models/trip-schedule";
import withAuthorization from "../../../../components/authorization";

import Loading from "../../../../components/loading";

const tripSchedulesStore = TripSchedules.create({
  count: 0,
  results: [],
  next: null,
  previous: null,
});

@inject("appDataStore")
@inject("tripsStore")
@observer
@withAuthorization({
  allowedRoles: ["cash_services_admin", "cash_unit_admin"],
  permissions: ["view_trip"],
})
class TripPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trip: undefined,
      isEditMode: false,
    };
  }
  async componentDidMount() {
    const { tripsStore } = this.props;
    const { tripId } = this.props.match.params;
    const response = await tripsStore.retrieve(tripId);
    if (response) {
      const trip = getChildType(tripsStore.results).create(response.data);
      this.setState({ trip });
    } else {
      this.setState({ trip: null });
    }
  }

  toggleEditMode = () => {
    const { isEditMode } = this.state;
    this.setState({ isEditMode: !isEditMode });
  };

  render() {
    const { tripsStore, appDataStore } = this.props;
    const { trip, isEditMode } = this.state;

    const { currentUser } = appDataStore;
    const { canChange, canDelete } = currentUser.getPermissions("trip");

    if (trip === undefined) {
      return <Loading />;
    } else if (trip === null) {
      return <NotFoundPage />;
    }

    return (
      <div className="animated fadeIn">
        <div className="row">
          <div className="col-8">
            <div className="card-container w-100">
              {!isEditMode && (
                <Details
                  data={trip}
                  isLoading={tripsStore.isLoading}
                  headerTitle="TRIP DETAILS"
                  onEdit={this.toggleEditMode}
                  deletable={canDelete}
                  editable={canChange}
                  // readOnly={getLocalDateToday() !== trip?.date}
                />
              )}
              {isEditMode && (
                <Form
                  data={trip}
                  headerTitle="EDIT TRIP DETAILS"
                  onClose={this.toggleEditMode}
                />
              )}
            </div>
          </div>
        </div>

        {/* Display objects related to this cash unit. */}
        <div>
          {trip && (
            <div className="mt-4">
              <div className="p-3">
                <h4>Trip Schedules</h4>
              </div>
              <Table
                {...this.props}
                store={tripSchedulesStore}
                filter={{ trip: trip.id }}
                objectKeys={[
                  "clientAddress",
                  "schedule",
                  "arrivalTime",
                  "departureTime",
                  "state",
                ]}
                linkToPath={"/config/trip-schedules/"}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default TripPreview;
