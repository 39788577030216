import React, { Component } from "react";

import { observer, inject } from "mobx-react";
import { getChildType } from "mobx-state-tree";

import Details from "./../../../../components/cards/details";
import Form from "./../../../../components/cards/form";
import NotFoundPage from "../../../others/not-found-page";
import withAuthorization from "../../../../components/authorization";
import Loading from "../../../../components/loading";

@inject("appDataStore")
@inject("extractTransactionDataRequestsStore")
@observer
@withAuthorization({
  allowedRoles: ["cash_services_admin", "data_science"],
  permissions: ["view_extracttransactiondatarequest"],
})
class ExtractTransactionDataRequestPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group: undefined,
      isEditMode: false,
    };
  }
  async componentDidMount() {
    const { extractTransactionDataRequestsStore } = this.props;
    const { extractTransactionDataRequestId } = this.props.match.params;
    const response = await extractTransactionDataRequestsStore.retrieve(
      extractTransactionDataRequestId
    );
    if (response) {
      const group = getChildType(
        extractTransactionDataRequestsStore.results
      ).create(response.data);
      this.setState({ group });
    } else {
      this.setState({ group: null });
    }
  }

  toggleEditMode = () => {
    const { isEditMode } = this.state;
    this.setState({ isEditMode: !isEditMode });
  };

  render() {
    const { extractTransactionDataRequestsStore, appDataStore } = this.props;
    const { group, isEditMode } = this.state;

    const { currentUser } = appDataStore;
    const { canChange, canDelete } = currentUser.getPermissions(
      "extracttransactiondatarequest"
    );

    if (group === null) {
      return <NotFoundPage />;
    } else if (group === undefined) {
      return <Loading />;
    }

    return (
      <div className="animated fadeIn">
        <div className="row">
          <div className="col-8">
            <div className="card-container w-100">
              {!isEditMode && (
                <Details
                  data={group}
                  isLoading={extractTransactionDataRequestsStore.isLoading}
                  headerTitle="TRANSACTION DATA REQUEST DETAILS"
                  onEdit={this.toggleEditMode}
                  editable={canChange}
                  deletable={canDelete}
                />
              )}
              {isEditMode && (
                <Form
                  data={group}
                  headerTitle="EDIT TRANSACTION DATA REQUEST DETAILS"
                  onClose={this.toggleEditMode}
                />
              )}
            </div>
          </div>
        </div>
        <div className="row">
          {/* Display objects related to this cash unit. */}
        </div>
      </div>
    );
  }
}

export default ExtractTransactionDataRequestPreview;
