import { types } from "mobx-state-tree";

import { BaseModel, Pagination } from ".";

import { CreateModelMixin, RetrieveModelMixin } from "./mixins";

import { Schedule, ScheduleByIdReference } from "./schedule";
import {
  PrePlannedTripByIdReference,
  PrePlannedTrip,
} from "./pre-planned-trip";

const PrePlannedTripScheduleFields = types.compose(
  CreateModelMixin,
  RetrieveModelMixin,
  types.model({
    prePlannedTrip: types.union(
      PrePlannedTripByIdReference,
      PrePlannedTrip,
      types.integer,
      types.null
    ),
    schedule: types.union(
      ScheduleByIdReference,
      Schedule,
      types.integer,
      types.null
    ),
    clientAddress: types.string,
    servicingType: types.string,
    verificationProcess: types.string,
  })
);

const PrePlannedTripSchedule = types.compose(
  BaseModel,
  PrePlannedTripScheduleFields
);

const PrePlannedTripSchedules = types
  .compose(
    Pagination,
    types.model({ results: types.array(PrePlannedTripSchedule) })
  )
  .volatile((self) => ({
    endpoint: "pre-planned-trip-schedules",
  }));

export {
  PrePlannedTripScheduleFields,
  PrePlannedTripSchedule,
  PrePlannedTripSchedules,
};
