import { types } from "mobx-state-tree";

import { requestWithToken } from "../../api";

import { BaseModel, Pagination } from ".";

import { CashUnitByIdReference } from "./cash-unit";

import { CreateModelMixin, RetrieveModelMixin } from "./mixins";
import { DateType } from "./datetime-type";

const ExtractTransactionDataRequestFields = types
  .compose(
    CreateModelMixin,
    RetrieveModelMixin,
    types.model({
      cashUnit: types.union(CashUnitByIdReference, types.integer, types.null),
      startDate: types.union(DateType, types.string, types.null),
      endDate: types.union(DateType, types.string, types.null),
      taskResultStatus: types.maybeNull(types.string),
    })
  )
  .views((self) => ({
    get display() {
      return "Some extracted transaction";
    },
  }))
  .volatile((self) => ({
    endpoint: "extract-transaction-data-request",
    readOnlyFields: ["taskResultStatus"],
  }));

const ExtractTransactionDataRequest = types.compose(
  ExtractTransactionDataRequestFields,
  BaseModel
);

const ExtractTransactionDataRequests = types
  .compose(
    Pagination,
    types.model({
      results: types.array(ExtractTransactionDataRequest),
    })
  )
  .volatile((self) => ({
    endpoint: "extract-transaction-data-request",
  }));

const ExtractTransactionDataRequestByIdReference = types.reference(
  ExtractTransactionDataRequest,
  {
    async get(value) {
      const response = await requestWithToken.get(
        `extract-transaction-data-request/${value}/`
      );
      let instance = ExtractTransactionDataRequest.create({
        ...response.data,
      });
      return instance;
    },
    set(value) {
      return value;
    },
  }
);

export {
  ExtractTransactionDataRequestFields,
  ExtractTransactionDataRequest,
  ExtractTransactionDataRequests,
  ExtractTransactionDataRequestByIdReference,
};
