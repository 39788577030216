import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

class Drawer extends React.Component {
  componentDidMount() {
    const { onClose } = this.props;
    window.$(this.modal).modal("show");
    window.$(this.modal).on("hidden.bs.modal", function (e) {
      onClose();
    });
  }

  componentWillUnmount() {
    window.$(this.modal).modal("hide");
  }

  render() {
    const { title, children, renderFooter } = this.props;
    return (
      <div
        className="modal fade drawer right-align"
        ref={(modal) => (this.modal = modal)}
        tabIndex="-1"
        role="dialog"
        data-backdrop="false"
        aria-hidden="true"
      >
        <div className="modal-dialog shadow-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <button
                type="button"
                className="secondary-color-btn"
                aria-label="Close"
                onClick={() => window.$(this.modal).modal("hide")}
              >
                <span aria-hidden="true">
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              </button>
            </div>
            <div className="modal-body p-0 m-0 d-flex flex-column flex-fill">
              {children}
            </div>
            <div className="modal-footer">{renderFooter}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Drawer;
