import { types } from "mobx-state-tree";

import { requestWithToken } from "../../api";

import { BaseModel, Pagination } from ".";

import { CreateModelMixin, RetrieveModelMixin } from "./mixins";

const MasterCifFields = types
  .compose(
    CreateModelMixin,
    RetrieveModelMixin,
    types.model({
      value: types.string,
    })
  )
  .views((self) => ({
    get display() {
      return self.value;
    },
  }))
  .actions((self) => ({
    setValue: ({ key, value }) => {
      self[key] = value;
    },
  }))
  .volatile((self) => ({
    endpoint: "master-cifs",
    // readOnly: true,
  }));

const MasterCif = types.compose(MasterCifFields, BaseModel);

const MasterCifs = types
  .compose(
    Pagination,
    types.model({
      results: types.array(MasterCif),
    })
  )
  .volatile((self) => ({
    endpoint: "master-cifs",
  }));

const MasterCifByIdReference = types.reference(MasterCif, {
  async get(value) {
    const response = await requestWithToken.get(`master-cifs/${value}/`);
    let masterCif = MasterCif.create({
      ...response.data,
    });
    return masterCif;
  },
  set(value) {
    return value;
  },
});

export { MasterCifFields, MasterCif, MasterCifs, MasterCifByIdReference };
