import React, { Component } from "react";

import { observer, inject } from "mobx-react";
import { getChildType } from "mobx-state-tree";

import Details from "./../../../../components/cards/details";
import Form from "./../../../../components/cards/form";
import NotFoundPage from "../../../others/not-found-page";

import Loading from "../../../../components/loading";
import withAuthorization from "../../../../components/authorization";

@inject("store")
@inject("appDataStore")
@inject("trucksStore")
@observer
@withAuthorization({
  allowedRoles: ["cash_services_admin", "cash_unit_admin"],
  permissions: ["view_truck"],
})
class TruckPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: false,
      truck: undefined,
    };
  }
  async componentDidMount() {
    const { trucksStore } = this.props;
    const { truckId } = this.props.match.params;
    const response = await trucksStore.retrieve(truckId);
    if (response) {
      const truck = getChildType(trucksStore.results).create(response.data);
      this.setState({ truck });
    } else {
      this.setState({ truck: null });
    }
  }

  toggleEditMode = () => {
    const { isEditMode } = this.state;
    this.setState({ isEditMode: !isEditMode });
  };

  render() {
    const { trucksStore, appDataStore } = this.props;
    const { isEditMode, truck } = this.state;

    const { currentUser } = appDataStore;
    const { canChange, canDelete } = currentUser.getPermissions("truck");

    if (truck === null) {
      return <NotFoundPage />;
    } else if (truck === undefined) {
      return <Loading />;
    }
    return (
      <div className="animated fadeIn">
        <div className="row">
          <div className="col-8">
            <div className="card-container w-100">
              {!isEditMode && (
                <Details
                  data={truck}
                  isLoading={trucksStore.isLoading}
                  headerTitle="TRUCK DETAILS"
                  onEdit={this.toggleEditMode}
                  editable={canChange}
                  deletable={canDelete}
                />
              )}
              {isEditMode && (
                <Form
                  data={truck}
                  headerTitle="EDIT TRUCK DETAILS"
                  onClose={this.toggleEditMode}
                />
              )}
            </div>
          </div>
        </div>
        <div className="row">
          {/* Display objects related to this cash unit. */}
        </div>
      </div>
    );
  }
}

export default TruckPreview;
