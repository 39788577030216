import { types } from "mobx-state-tree";

import { BaseModel, Pagination } from ".";
import { requestWithToken } from "../../api";
import { CreateModelMixin, RetrieveModelMixin } from "./mixins";

const TruckProviderFields = types
  .compose(
    CreateModelMixin,
    RetrieveModelMixin,
    types.model({
      name: types.string,
    })
  )
  .volatile((self) => ({
    endpoint: "truck-providers",
  }))
  .views((self) => ({
    get display() {
      return self.name;
    },
  }));

const TruckProvider = types.compose(BaseModel, TruckProviderFields);

const TruckProviders = types
  .compose(Pagination, types.model({ results: types.array(TruckProvider) }))
  .volatile((self) => ({
    endpoint: "truck-providers",
  }));

const TruckProviderByIdReference = types.reference(TruckProvider, {
  async get(value) {
    const response = await requestWithToken.get(`truck-providers/${value}/`);
    let truckProvider = TruckProviderFields.create({ ...response.data });
    return truckProvider;
  },
  set(value) {
    return value;
  },
});

export {
  TruckProviderFields,
  TruckProvider,
  TruckProviders,
  TruckProviderByIdReference,
};
