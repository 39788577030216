import React, { Component } from "react";
import { observer } from "mobx-react";
import DisplayValue from "../display-value";

@observer
class DispatchedTrucks extends Component {
  render() {
    const { trips, recentlyUpdatedTrips, onClick } = this.props;

    return (
      <div>
        <div className="card-container w-100 p-0">
          <div className="config-card-list box-shadow--light">
            <div className="min-vh-50 config-card__body">
              <div className="row config-card__list-head">
                <div className="col-2 config-card__list-head-item">Truck</div>
                <div className="col-1 config-card__list-head-item">
                  Latitude
                </div>
                <div className="col-1 config-card__list-head-item">
                  Longitude
                </div>
                <div className="col-3 config-card__list-head-item">
                  Current Trip Schedule
                </div>
                <div className="col-2 config-card__list-head-item">
                  Cash Unit
                </div>
                <div className="col-2 config-card__list-head-item">Teller</div>
                <div className="col-1 config-card__list-head-item"></div>
              </div>
              <div className="config-card__list-body">
                {trips.length === 0 ? (
                  <div className="d-block text-center p-4">
                    <span className="muted-text">No trip found.</span>
                  </div>
                ) : (
                  trips.map((trip, index) => {
                    const { currentTripSchedule } = trip;
                    const recentlyUpdatedTrip = recentlyUpdatedTrips.find(
                      (t) => t.id === trip.id
                    );
                    const _currentTripSchedule = trip.tripSchedules.find(
                      (ts) => ts.id === currentTripSchedule
                    );
                    const _isCompleted = trip.tripSchedules.every(
                      (ts) => ts.state === "DONE"
                    );
                    return (
                      <div
                        key={`${index} - ${JSON.stringify(trip)}`}
                        className={`row config-card__list-body-item ${
                          recentlyUpdatedTrip ? "config-card__new-item" : ""
                        }`}
                        onClick={() => onClick(trip)}
                      >
                        <div className="col-2">
                          <div>
                            {trip.truck
                              ? trip.truck.callSign
                              : "No truck assigned."}
                          </div>
                          <div>
                            {trip.truck && (
                              <>
                                <span
                                  className={`badge badge-${
                                    trip.truckGps?.isOnline ? "dark" : "light"
                                  }`}
                                >
                                  {trip.truckGps.isOnline
                                    ? "Online"
                                    : "Offline"}
                                </span>

                                <span
                                  className={`badge badge-${
                                    trip.truck.status === "WORKING"
                                      ? "info"
                                      : "danger"
                                  }`}
                                >
                                  {trip.truck.status}
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="col-1">
                          {!isNaN(parseFloat(trip.truckGps?.latitude))
                            ? parseFloat(trip.truckGps.latitude).toFixed(4)
                            : "--"}
                        </div>
                        <div className="col-1">
                          {!isNaN(parseFloat(trip.truckGps?.longitude))
                            ? parseFloat(trip.truckGps.longitude).toFixed(4)
                            : "--"}
                        </div>
                        <div className="col-3">
                          <div>
                            <DisplayValue
                              value={
                                _currentTripSchedule?.schedule.clientAddress
                                  .name
                              }
                            />
                          </div>
                          <div>
                            <span className="badge badge-light">
                              {_currentTripSchedule?.state}
                            </span>
                            <span className="badge badge-secondary">
                              {_currentTripSchedule?.schedule.servicingType}
                            </span>
                            <span className="badge badge-dark">
                              {
                                _currentTripSchedule?.schedule
                                  .verificationProcess
                              }
                            </span>

                            {_isCompleted && (
                              <span className="badge badge-primary">
                                COMPLETED
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-2">
                          {trip.cashUnit?.cashUnitIdentifier}
                        </div>
                        <div className="col-2">
                          {trip.teller
                            ? `${trip.teller.firstName} "${trip.teller.nickname}" ${trip.teller.lastName}`
                            : "No teller assigned."}
                        </div>
                        <div className="col-1">
                          <button
                            className="btn btn-outline-dark"
                            onClick={(e) => {
                              e.stopPropagation();
                              window.location.assign(
                                `/config/trips/${trip.id}`
                              );
                            }}
                          >
                            View Details
                          </button>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DispatchedTrucks;
