import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import UsersNavbar from "./../../../components/navbar/users-navbar";
import ActiveUsers from "./active-users";
import ActiveUserPreview from "./active-users/preview";
import InactiveUsers from "./inactive-users";
import InactiveUserPreview from "./inactive-users/preview";

export default class Users extends Component {
  render() {
    return (
      <div className="p-0 config-container container-fluid animated fadeIn">
        <div className="row no-gutters">
          <div className="col-md-4 col-lg-3 col-xl-2">
            <UsersNavbar />
          </div>
          <div className="config-content col-md-8 col-lg-9 col-xl-10">
            <Switch>
              <Route
                path="/users/active/:userId"
                component={ActiveUserPreview}
              />
              <Route path="/users/active" component={ActiveUsers} />

              <Route
                path="/users/inactive/:userId"
                component={InactiveUserPreview}
              />
              <Route path="/users/inactive" component={InactiveUsers} />
              <Route>
                <Redirect to="/users/active" />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}
