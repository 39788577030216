import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

class ConfigNavbar extends Component {
  render() {
    let path = this.props.location.pathname;
    return (
      <div className="config-navbar">
        <div className="config-navbar__page-name">Configuration</div>
        <div className="config-navbar__navigation">
          <Link to="/config/cashunits">
            <div
              className={`config-navbar__nav-item ${
                path.includes("/config/cashunits") ? "active" : ""
              }`}
            >
              <span className="config-navbar__nav-item-text">Cash Units</span>
              <FontAwesomeIcon
                className={`config-navbar__nav-item-icon ${
                  path.includes("/config/cashunits") ? "" : "d-none"
                }`}
                icon={faChevronRight}
              />
            </div>
          </Link>
          <Link to="/config/cash-unit-personnels">
            <div
              className={`config-navbar__nav-item ${
                path.includes("/config/cash-unit-personnels") ? "active" : ""
              }`}
            >
              <span className="config-navbar__nav-item-text">
                Cash Unit Personnels
              </span>
              <FontAwesomeIcon
                className={`config-navbar__nav-item-icon ${
                  path.includes("/config/cash-unit-personnels") ? "" : "d-none"
                }`}
                icon={faChevronRight}
              />
            </div>
          </Link>
          <hr />
          <Link to="/config/groups">
            <div
              className={`config-navbar__nav-item ${
                path.includes("/config/groups") ? "active" : ""
              }`}
            >
              <span className="config-navbar__nav-item-text">Groups</span>
              <FontAwesomeIcon
                className={`config-navbar__nav-item-icon ${
                  path.includes("/config/groups") ? "" : "d-none"
                }`}
                icon={faChevronRight}
              />
            </div>
          </Link>
          <Link to="/config/client-addresses">
            <div
              className={`config-navbar__nav-item ${
                path.includes("/config/client-addresses") ? "active" : ""
              }`}
            >
              <span className="config-navbar__nav-item-text">
                Client Addresses
              </span>
              <FontAwesomeIcon
                className={`config-navbar__nav-item-icon ${
                  path.includes("/config/client-addresses") ? "" : "d-none"
                }`}
                icon={faChevronRight}
              />
            </div>
          </Link>
          <Link to="/config/schedules">
            <div
              className={`config-navbar__nav-item ${
                path.includes("/config/schedules") ? "active" : ""
              }`}
            >
              <span className="config-navbar__nav-item-text">
                Client Schedules
              </span>
              <FontAwesomeIcon
                className={`config-navbar__nav-item-icon ${
                  path.includes("/config/schedules") ? "" : "d-none"
                }`}
                icon={faChevronRight}
              />
            </div>
          </Link>
          <hr />
          <Link to="/config/truck-providers">
            <div
              className={`config-navbar__nav-item ${
                path.includes("/config/truck-providers") ? "active" : ""
              }`}
            >
              <span className="config-navbar__nav-item-text">
                Truck Providers
              </span>
              <FontAwesomeIcon
                className={`config-navbar__nav-item-icon ${
                  path.includes("/config/truck-providers") ? "" : "d-none"
                }`}
                icon={faChevronRight}
              />
            </div>
          </Link>
          <Link to="/config/trucks">
            <div
              className={`config-navbar__nav-item ${
                path.includes("/config/trucks") ? "active" : ""
              }`}
            >
              <span className="config-navbar__nav-item-text">Trucks</span>
              <FontAwesomeIcon
                className={`config-navbar__nav-item-icon ${
                  path.includes("/config/trucks") ? "" : "d-none"
                }`}
                icon={faChevronRight}
              />
            </div>
          </Link>
          <Link to="/config/truck-personnels">
            <div
              className={`config-navbar__nav-item ${
                path.includes("/config/truck-personnels") ? "active" : ""
              }`}
            >
              <span className="config-navbar__nav-item-text">
                Truck Personnels
              </span>
              <FontAwesomeIcon
                className={`config-navbar__nav-item-icon ${
                  path.includes("/config/truck-personnels") ? "" : "d-none"
                }`}
                icon={faChevronRight}
              />
            </div>
          </Link>

          <hr />
          <Link to="/config/trips">
            <div
              className={`config-navbar__nav-item ${
                path.includes("/config/trips") ? "active" : ""
              }`}
            >
              <span className="config-navbar__nav-item-text">Trips</span>
              <FontAwesomeIcon
                className={`config-navbar__nav-item-icon ${
                  path.includes("/config/trips") ? "" : "d-none"
                }`}
                icon={faChevronRight}
              />
            </div>
          </Link>
          <Link to="/config/trip-schedules">
            <div
              className={`config-navbar__nav-item ${
                path.includes("/config/trip-schedules") ? "active" : ""
              }`}
            >
              <span className="config-navbar__nav-item-text">
                Trip Schedules
              </span>
              <FontAwesomeIcon
                className={`config-navbar__nav-item-icon ${
                  path.includes("/config/trip-schedules") ? "" : "d-none"
                }`}
                icon={faChevronRight}
              />
            </div>
          </Link>

          <hr />
          <Link to="/config/address-geocodes">
            <div
              className={`config-navbar__nav-item ${
                path.includes("/config/address-geocodes") ? "active" : ""
              }`}
            >
              <span className="config-navbar__nav-item-text">
                Address Geocodes
              </span>
              <FontAwesomeIcon
                className={`config-navbar__nav-item-icon ${
                  path.includes("/config/address-geocodes") ? "" : "d-none"
                }`}
                icon={faChevronRight}
              />
            </div>
          </Link>

          <hr />
          <Link to="/config/transaction-data-requests">
            <div
              className={`config-navbar__nav-item ${
                path.includes("/config/transaction-data-requests")
                  ? "active"
                  : ""
              }`}
            >
              <span className="config-navbar__nav-item-text">
                Transaction Data Requests
              </span>
              <FontAwesomeIcon
                className={`config-navbar__nav-item-icon ${
                  path.includes("/config/transaction-data-requests")
                    ? ""
                    : "d-none"
                }`}
                icon={faChevronRight}
              />
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export default ConfigNavbar = withRouter(ConfigNavbar);
