import { types } from "mobx-state-tree";

import { requestWithToken } from "../../api";

import { BaseModel, Pagination } from ".";

import { CreateModelMixin, RetrieveModelMixin } from "./mixins";

import { AddressGeocodeByIdReference, AddressGeocode } from "./address-geocode";

const CashUnitType = types.enumeration("Enum", [
  "CASH_CENTER",
  "BRANCH_BASED_SERVICING_UNIT",
]);

const CashUnitFields = types
  .compose(
    CreateModelMixin,
    RetrieveModelMixin,
    types.model({
      cashUnitIdentifier: types.string,
      name: types.string,
      type: types.union(CashUnitType, types.literal("")),
      address: types.string,
      addressGeocode: types.union(
        AddressGeocodeByIdReference,
        AddressGeocode,
        types.integer,
        types.null,
        types.undefined
      ),
      rcCode: types.string,
      cashUnitConfiguration: types.maybeNull(types.integer),
    })
  )
  .volatile((self) => ({
    endpoint: "cash-units",
    hiddenFields: ["addressGeocode", "cashUnitConfiguration"],
  }))
  .views((self) => ({
    get display() {
      return self.cashUnitIdentifier;
    },
  }));

const CashUnit = types.compose(CashUnitFields, BaseModel).actions((self) => ({
  setName: (name) => {
    self.name = name;
  },
  setCashUnitIdentifier: (cashUnitIdentifier) => {
    self.cashUnitIdentifier = cashUnitIdentifier;
  },
  setType: (type) => {
    self.type = type;
  },
}));

const CashUnits = types
  .compose(
    Pagination,
    types.model({
      results: types.array(CashUnit),
    })
  )
  .volatile((self) => ({
    endpoint: "cash-units",
  }));

const CashUnitByIdReference = types.reference(CashUnit, {
  async get(value) {
    const response = await requestWithToken.get(`cash-units/${value}/`);
    let cashUnit = CashUnit.create({
      ...response.data,
    });
    return cashUnit;
  },
  set(value) {
    return value;
  },
});

export {
  CashUnit,
  CashUnits,
  CashUnitType,
  CashUnitFields,
  CashUnitByIdReference,
};
