import { types } from "mobx-state-tree";

import { BaseFilters, BaseModel, Pagination, User, UserByIdReference } from ".";
import { requestWithToken } from "../../api";
import { CashUnit, CashUnitByIdReference } from "./cash-unit";
import { ActionMixin, CreateModelMixin, RetrieveModelMixin } from "./mixins";

const CashUnitPersonnelFilters = types.compose(
  ActionMixin,
  BaseFilters,
  types.model({
    isGeofencingActive: types.union(
      types.enumeration("Enum", ["True", "False"]),
      types.literal("")
    ),
    isTeller: types.union(
      types.enumeration("Enum", ["True", "False"]),
      types.literal("")
    ),
  })
);

const CashUnitPersonnelFields = types
  .compose(
    CreateModelMixin,
    RetrieveModelMixin,
    types.model({
      firstName: types.string,
      lastName: types.string,
      employeeId: types.string,
      nickname: types.string,
      user: types.union(UserByIdReference, User, types.integer, types.null),
      username: types.union(types.string, types.null, types.undefined),
      cashUnit: types.union(
        CashUnitByIdReference,
        CashUnit,
        types.integer,
        types.null
      ),
      cashUnitIdentifier: types.maybeNull(types.string),
      isTeller: types.boolean,
      isGeofencingActive: types.boolean,
    })
  )
  .views((self) => ({
    get display() {
      return self.fullName;
    },
    get fullName() {
      return `${self.firstName} "${self.nickname}" ${self.lastName}`;
    },
  }))
  .volatile((self) => ({
    endpoint: "cash-unit-personnels",
    readOnlyFields: ["username", "cashUnitIdentifier"],
    hiddenFields: ["username", "cashUnitIdentifier"],
  }));

const CashUnitPersonnel = types.compose(BaseModel, CashUnitPersonnelFields);

const CashUnitPersonnels = types
  .compose(
    Pagination,
    types.model({
      results: types.array(CashUnitPersonnel),
    })
  )
  .volatile((self) => ({
    endpoint: "cash-unit-personnels",
  }));

const CashUnitPersonnelByIdReference = types.reference(CashUnitPersonnel, {
  async get(value) {
    const response = await requestWithToken.get(
      `cash-unit-personnels/${value}/`
    );
    let obj = CashUnitPersonnel.create({
      ...response.data,
    });
    return obj;
  },
  set(value) {
    return value;
  },
});

export {
  CashUnitPersonnelFields,
  CashUnitPersonnel,
  CashUnitPersonnels,
  CashUnitPersonnelByIdReference,
  CashUnitPersonnelFilters,
};
