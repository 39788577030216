import React, { Component } from "react";

import { MsalProvider } from "@azure/msal-react";

import AppView, { msalInstance } from "./app-view";
import { AppProvider } from "../providers/app";

class App extends Component {
  render() {
    return (
      <MsalProvider instance={msalInstance}>
        <AppProvider>
          <AppView />
        </AppProvider>
      </MsalProvider>
    );
  }
}
export default App;
