import React, { Component } from "react";
import Table from "../../../../components/cards/table";
import { observer, inject } from "mobx-react";

import { ExtractTransactionDataRequestFields } from "../../../../data/models/extract-transaction-data-request";
import withAuthorization from "../../../../components/authorization";
import FormDrawer from "../../../../components/form-drawer";
import Form from "../../../../components/cards/form";

const ROUTE_URL = "/config/transaction-data-requests/";

@inject("extractTransactionDataRequestsStore")
@inject("appDataStore")
@observer
@withAuthorization({
  allowedRoles: ["cash_services_admin", "cash_unit_admin"],
  permissions: ["view_extracttransactiondatarequest"],
})
class ExtractTransactionDataRequests extends Component {
  showNewlyAdded = (item) => {
    window.location.assign(`${ROUTE_URL}${item.id}`);
  };

  render() {
    const { extractTransactionDataRequestsStore, appDataStore } = this.props;
    const { currentUser } = appDataStore;
    const { canAdd, canView } = currentUser.getPermissions(
      "extracttransactiondatarequest"
    );

    return (
      <div className="animated fadeIn">
        <div className="d-flex justify-content-between">
          <div className="config-breadcrumbs ">
            <span className="active">Transaction Data Requests</span>
          </div>
          {canAdd && (
            <FormDrawer
              title="Request Transaction Data"
              triggerButton={
                <button
                  className="primary-color-btn"
                  data-testid="form-submit-item"
                >
                  Request Transaction Data
                </button>
              }
              submitButton={
                <button
                  type="button"
                  className="primary-color-btn"
                  data-testid="form-submit-item"
                  onClick={async () => await this.submitHandler()}
                >
                  Submit
                </button>
              }
            >
              <Form
                mode="form"
                data={ExtractTransactionDataRequestFields.create({
                  cashUnit: null,
                  startDate: null,
                  endDate: null,
                })}
                onSubmit={this.showNewlyAdded}
                submitHandler={(submitHandler) => {
                  this.submitHandler = submitHandler;
                }}
              />
            </FormDrawer>
          )}
        </div>

        <Table
          {...this.props}
          store={extractTransactionDataRequestsStore}
          objectKeys={[
            "cashUnit",
            "startDate",
            "endDate",
            "taskResultStatus",
            "createdBy",
          ]}
          linkToPath={ROUTE_URL}
          canView={canView}
        />
      </div>
    );
  }
}

export default ExtractTransactionDataRequests;
