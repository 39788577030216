import React, { Component } from "react";

import { reaction } from "mobx";
import { observer, inject } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import alertify from "alertifyjs";

import DispatchedTrucks from "../../../components/cards/dispatched-trucks";

import WebSocket from "../../../components/websocket";
import { WS_TRIPS_URL } from "../../../api";
import GoogleMaps from "../../../components/google-maps";
import { DetailedTrip } from "../../../data/models/trip";
import { DashboardFilter } from "../../../data/stores";
import Form from "../../../components/cards/form";

alertify.defaults.transition = "zoom";
alertify.defaults.theme.ok = "primary-color-btn";
alertify.defaults.theme.cancel = "secondary-color-btn";

const mapContainerStyle = {
  width: "100%",
  height: "60vh",
  padding: "10px",
};

const activeTrucks = {
  MCH_DAVAO: [
    {
      dayOfWeek: "Monday",
      activeCars: 4,
      clients: 34,
    },
    {
      dayOfWeek: "Tuesday",
      activeCars: 4,
      clients: 28,
    },
    {
      dayOfWeek: "Wednesday",
      activeCars: 4,
      clients: 35,
    },
    {
      dayOfWeek: "Thursday",
      activeCars: 4,
      clients: 27,
    },
    {
      dayOfWeek: "Friday",
      activeCars: 4,
      clients: 35,
    },
    {
      dayOfWeek: "Saturday",
      activeCars: 4,
      clients: 6,
    },
    {
      dayOfWeek: "Sunday",
      activeCars: 4,
      clients: 0,
    },
  ],
};

@inject("appDataStore")
@observer
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trips: [],
      tripsData: [],
      recentlyUpdatedTrips: [],
      hasInitialized: false,
      clientAddresses: [],
      tripSchedules: [],
      googleMapsFilter: DashboardFilter.create({ cashUnit: null, truck: null }),
      wsTripsUrl: null,
    };
    this._cache = null;
  }

  componentDidMount() {
    const { appDataStore } = this.props;
    console.log("DASHBOARD MOUNTED");
    if (appDataStore.accessToken) {
      const wsTripsUrl = `${WS_TRIPS_URL}?${appDataStore.accessToken
        .replace("Bearer ", "bearer=")
        .replace("Token ", "token=")}`;
      this.setState({ wsTripsUrl });
    }
    this.accessTokenDisposer = reaction(
      () => appDataStore.accessToken,
      (accessToken) => {
        if (accessToken) {
          const wsTripsUrl = `${WS_TRIPS_URL}?${accessToken
            .replace("Bearer ", "bearer=")
            .replace("Token ", "token=")}`;
          this.setState({ wsTripsUrl });
        }
      }
    );
  }

  componentWillUnmount() {
    this.accessTokenDisposer();
    console.log("DASHBOARD UNMOUNTED");
  }

  onTripMessage = (message) => {
    if (this._cache !== message) {
      let data = JSON.parse(message);
      this.setState({
        tripsData: data,
      });
      this._cache = message;
    }
  };

  getTripData = () => {
    let recentlyUpdatedTrips = [];
    let _trips = [];

    const { googleMapsFilter, trips, tripsData } = this.state;

    tripsData.forEach((tripData) => {
      const trip = DetailedTrip.create(tripData);
      _trips.push(trip);
    });

    // convert to json to get the id instead of Promise to fetch the value
    const googleMapsFilterJSON = googleMapsFilter.toJSON();
    const filteredTrips = _trips.filter(
      (trip) =>
        (googleMapsFilterJSON.cashUnit === null ||
          googleMapsFilterJSON.cashUnit === trip.cashUnit?.id) &&
        (googleMapsFilterJSON.truck === null ||
          googleMapsFilterJSON.truck === trip.truck?.id)
    );

    const filteredClientAddresses = filteredTrips
      .map((trip) => trip.tripSchedules)
      .flat()
      .map((tripSchedule) => tripSchedule.schedule.clientAddress);

    filteredTrips.forEach((trip) => {
      let previousTrip = trips.find(
        (previousTrip) => previousTrip.id === trip.id
      );
      if (!previousTrip) {
        recentlyUpdatedTrips.push(trip);
      } else if (
        previousTrip.id === trip.id &&
        JSON.stringify(getSnapshot(previousTrip)) !==
          JSON.stringify(getSnapshot(trip))
      ) {
        recentlyUpdatedTrips.push(trip);
      }
    });

    return {
      trips: filteredTrips,
      clientAddresses: filteredClientAddresses,
      recentlyUpdatedTrips,
    };
  };

  render() {
    const { googleMapsFilter, wsTripsUrl } = this.state;
    const { trips, clientAddresses, recentlyUpdatedTrips } = this.getTripData();

    return (
      <div className="dashboard-container">
        <div className="dashboard-content">
          {wsTripsUrl && (
            <WebSocket url={wsTripsUrl} onMessage={this.onTripMessage} />
          )}
          {Object.keys(activeTrucks).map((cashUnit) => (
            <div className="row mb-4">
              <div className="col-12">
                <h3>{cashUnit}</h3>
              </div>
              {activeTrucks[cashUnit].map((truck) => (
                <div className="col">
                  <div className="card card-stats mb-4 mb-xl-0">
                    <div className="card-body">
                      <h5 className="card-title text-uppercase mb-0 font-weight-bold">
                        {truck.dayOfWeek}
                      </h5>
                      <p className="d-flex flex-row mt-3 mb-0 text-muted text-sm justify-content-between">
                        <span className="mr-2">{truck.clients} </span>
                        <span className="text-nowrap">Clients</span>
                      </p>
                      <p className="d-flex flex-row mb-0 text-muted text-sm justify-content-between">
                        <span className="mr-2">{truck.activeCars} </span>
                        <span className="text-nowrap">Trucks</span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
          <div>
            <div className="row">
              <div className="col-9">
                <GoogleMaps
                  ref={(ref) => (this.maps = ref)}
                  containerStyle={mapContainerStyle}
                  markers={{
                    trips: trips
                      .filter((trip) => trip.truckGps)
                      .map((trip) => ({
                        position: {
                          lat: parseFloat(trip.truckGps.latitude),
                          lng: parseFloat(trip.truckGps.longitude),
                        },
                        label: trip.truck.callSign,
                      })),
                    clientAddresses: clientAddresses
                      .filter((clientAddress) => clientAddress.addressGeocode)
                      .map((clientAddress) => ({
                        position: {
                          lat: parseFloat(
                            clientAddress.addressGeocode.latitude
                          ),
                          lng: parseFloat(
                            clientAddress.addressGeocode.longitude
                          ),
                        },
                        label: clientAddress.name,
                      })),
                  }}
                />
              </div>
              <div className="col-3">
                <Form
                  data={googleMapsFilter}
                  headerTitle="Google Maps Filter"
                  mode="form"
                />
              </div>
            </div>

            <DispatchedTrucks
              trips={trips}
              recentlyUpdatedTrips={recentlyUpdatedTrips}
              onClick={(trip) => {
                if (
                  trip &&
                  trip.truckGps?.latitude &&
                  trip.truckGps?.longitude &&
                  this.maps
                ) {
                  this.maps.setCenter({
                    lat: parseFloat(trip.truckGps.latitude),
                    lng: parseFloat(trip.truckGps.longitude),
                  });
                  window.scrollTo(0, 0);
                } else {
                  alertify.error(
                    "There's no location for selected truck to set the center of the map."
                  );
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
