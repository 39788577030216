import React, { Component } from "react";
import UnauthorizedPage from "../scenes/others/unauthorized-page";

// Authorization HOC
const withAuthorization =
  ({ allowedRoles, permissions = [] }) =>
  (WrappedComponent) =>
    class Authorization extends Component {
      render() {
        const { appDataStore } = this.props;

        if (!appDataStore) {
          throw Error("Please inject the `appDataStore`");
        }

        const { currentUser } = appDataStore;

        if (!currentUser) {
          throw Error("User does not exist!");
        }

        const checkAccess = ({
          allowedRoles,
          permissions,
        }: {
          allowedRoles: [],
          permissions: [],
        }) => {
          if (allowedRoles && allowedRoles.length > 0) {
            return (
              currentUser.permissionGroups.find((permissionGroup) =>
                allowedRoles.includes(permissionGroup)
              ) ||
              currentUser.permissions.find((permission) =>
                permissions.includes(permission)
              )
            );
          }

          return true;
        };

        const canAccess = checkAccess({ allowedRoles, permissions });

        return canAccess ? (
          <WrappedComponent {...this.props} />
        ) : (
          <UnauthorizedPage />
        );
      }
    };

export default withAuthorization;
