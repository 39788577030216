import React from "react";

class DetailsNotFound extends React.Component {
  render() {
    return (
      <div className="min-vh-50 config-card__body">
        <div className="config-card__details-container">
          <div className="d-inline-block w-50 align-top">No data found.</div>
        </div>
      </div>
    );
  }
}

export default DetailsNotFound;
