import { observer } from "mobx-react";
import React, { PureComponent } from "react";
import Select, { components } from "react-select";
import _ from "lodash";
import DisplayValue from "./display-value";

const Input = (props) => <components.Input {...props} isHidden={false} />;

/* A mix of controlled and uncontrolled pattern.
I'm storing internal states as I wanted to resolve promised data in this component.
*/
@observer
class DisplayInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      inputValue: "",
      options: [],
    };
    this.ref = React.createRef();
  }

  async componentDidMount() {
    await this.updateValue();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      await this.updateValue();
    }
  }

  updateValue = async () => {
    const { type, value, readOnly } = this.props;
    if (!readOnly) {
      // gracefully check if the value is a promise from a reference field.
      const resolveValue = await Promise.resolve(value);
      if (type === "reference") {
        this.setState({
          value: { label: resolveValue?.display, value: resolveValue?.id },
          inputValue: resolveValue?.display || "",
        });
      } else {
        this.setState({ value: resolveValue });
      }
    } else {
      this.setState({ value });
    }
  };

  render() {
    const {
      type,
      name,
      callback,
      required,
      disabled,
      child,
      isLoading,
      readOnly,
    } = this.props;
    const { value } = this.state;
    if (readOnly) {
      return <DisplayValue type={type} value={value} />;
    } else if (type === "integer") {
      return (
        <input
          type="number"
          className="input-field"
          name={name}
          value={value || 0}
          onChange={(event) => {
            try {
              callback(parseInt(event.target.value));
            } catch {
              callback(0);
            }
          }}
          required={required}
          disabled={disabled}
        />
      );
    } else if (type === "string") {
      return (
        <input
          type="text"
          className="input-field"
          name={name}
          value={value || ""}
          onChange={callback}
          required={required}
          disabled={disabled}
        />
      );
    } else if (type === "boolean") {
      return (
        <div className="switch">
          <input
            type="checkbox"
            value={true}
            data-val={true}
            checked={value || false}
            name={name}
            data-toggle="toggle"
            onChange={() => {}}
            disabled={disabled}
          />
          <span
            className={`slider ${disabled ? "disabled" : ""}`}
            onClick={() => {
              callback(!value);
            }}
          ></span>
        </div>
      );
    } else if (type === "DateTime") {
      return (
        <div>
          <input
            type="datetime-local"
            className="input-field"
            value={value?.format("YYYY-MM-DD hh:mm")}
            name={name}
            onChange={(event) => {
              callback(event.target.value || null);
            }}
            disabled={disabled}
          />
        </div>
      );
    } else if (type === "Time") {
      return (
        <input
          type="time"
          className="input-field"
          value={value || ""}
          name={name}
          onChange={callback}
          disabled={disabled}
        />
      );
    } else if (type === "Date") {
      return (
        <input
          type="date"
          className="input-field"
          value={value || ""}
          name={name}
          onChange={callback}
          disabled={disabled}
        />
      );
    } else if (type === "Enum") {
      return (
        <select
          value={value || ""}
          name={name}
          onChange={callback}
          required={required}
          disabled={disabled}
          placeholder="Select"
          className="dropdown-input"
        >
          <option value="" selected disabled>
            - Select Here -
          </option>
          {child()}
        </select>
      );
    } else if (type === "reference") {
      const { inputValue, options } = this.state;
      const { searchableSelectOptions } = this.props;
      return searchableSelectOptions ? (
        <Select
          ref={this.ref}
          name={name}
          isSearchable
          isClearable={!required}
          value={value}
          inputValue={inputValue}
          blurInputOnSelect={true}
          isDisabled={disabled}
          onChange={(option) => {
            this.setState({ inputValue: option?.label || "" });
            callback(option);
          }}
          isLoading={isLoading || searchableSelectOptions.isLoading}
          onInputChange={(inputValue, { action }) => {
            if (action === "input-change") {
              this.setState({ inputValue }, async () => {
                callback(null);
                await searchableSelectOptions.loadOptions(
                  inputValue,
                  (options) => {
                    this.setState({ options });
                  }
                );
              });
            }
          }}
          options={[
            ...new Map(options.map((item) => [item["value"], item])).values(),
          ]}
          onFocus={(event) => {
            if (inputValue) {
              this.ref.current.select.inputRef.select();
            }
            searchableSelectOptions
              .loadOptions(inputValue, (options) => {
                this.setState({ options });
              })
              .then(() => {});
          }}
          controlShouldRenderValue={false}
          placeholder={`Search ${_.startCase(name)}`}
          components={{
            Input,
          }}
        />
      ) : (
        <DisplayValue type={type} value={value} />
      );
    } else {
      return <DisplayValue type={type} value={value} />;
    }
  }
}

export default DisplayInput;
