import _ from "lodash";
import React, { Component } from "react";
import { observer } from "mobx-react";
import { getType } from "mobx-state-tree";

import alertify from "alertifyjs";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";

import { BaseModel } from "../../data/models";

import Loading from "./../../components/loading";
import DisplayValue from "../display-value";

import { getModelDetails } from "../../utils";

alertify.defaults.transition = "zoom";
alertify.defaults.theme.ok = "primary-color-btn";
alertify.defaults.theme.cancel = "secondary-color-btn";

/* TODO: Can't fix the request on reference field every mount of this component.
As I can't assign the resolve value to the field. (Dynamic observer not allowed.)

Trade-off for easy of development for the auto generation of forms and view components.
API will only return the ID, then the DisplayValue component 
will resolve the reference by fetching the details using the ID.
*/
@observer
class Details extends Component {
  confirmDelete = (instance) => {
    alertify.confirm(
      `CONFIRM DELETE`,
      `Are you sure you want to delete this item?`,
      async () => {
        const response = await instance.destroy();
        if (response.status >= 200 && response.status < 300) {
          alertify.success(`Successfully deleted. ${response.data}`);
          const content = window.location.pathname.split("/");
          window.location.assign(
            content.splice(0, content.length - 1).join("/")
          );
        } else {
          alertify.error(`There's a problem deleting. ${response.data}`);
        }
      },
      () => console.log(`Delete prompt cancelled.`)
    );
  };

  render() {
    const {
      headerTitle,
      onEdit,
      isLoading,
      data,
      deletable,
      editable,
      readOnly,
    } = this.props;
    const instance = data;
    let targetFields = [];

    if (instance) {
      targetFields = getType(instance).propertyNames.filter(
        (name) => !BaseModel.propertyNames.includes(name)
      );
      if (instance.hiddenFields) {
        targetFields = targetFields.filter(
          (field) => !instance.hiddenFields.includes(field)
        );
      }
    }
    return (
      <div className="config-card-details box-shadow--light">
        <div className="config-card__header">
          <div className="config-card__header-title">{headerTitle}</div>
          {instance && !instance.readOnly && !readOnly && (
            <div className="config-card__header-button">
              {deletable && (
                <button
                  type="button"
                  data-testid="delete-item"
                  className="error-color-btn"
                  onClick={() => this.confirmDelete(instance)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              )}
              {onEdit && editable && (
                <button
                  type="button"
                  data-testid="edit-item"
                  className="secondary-color-btn"
                  onClick={onEdit}
                >
                  <FontAwesomeIcon icon={faPen} />
                </button>
              )}
            </div>
          )}
        </div>
        {!instance || isLoading ? (
          <div className="config-card__body">
            <Loading />
          </div>
        ) : (
          <div className="config-card__body">
            <div className="config-card__details-container">
              <div className="d-inline-block w-50 align-top">
                {getModelDetails(instance, targetFields).map((field, index) => {
                  return (
                    <div key={index} className="cashcenter-card__detail-item">
                      <span className="label">
                        {_.startCase(field.name).toUpperCase()}
                      </span>
                      <DisplayValue {...field} />
                    </div>
                  );
                })}
              </div>
              <div className="d-inline-block w-50 align-top">
                {getModelDetails(
                  instance,
                  instance.hiddenFields
                    ? BaseModel.propertyNames.filter(
                        (field) => !instance.hiddenFields.includes(field)
                      )
                    : BaseModel.propertyNames
                ).map((field, index) => {
                  return (
                    <div
                      data-testid={field.name}
                      key={index}
                      className="cashcenter-card__detail-item"
                    >
                      <span className="label">
                        {_.startCase(field.name).toUpperCase()}
                      </span>
                      <DisplayValue {...field} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

Details.defaultProps = {
  deletable: true,
  editable: true,
  readOnly: false,
};

export default Details;
