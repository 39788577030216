import _ from "lodash";

import moment from "moment-timezone";
import { getMembers, getChildType } from "mobx-state-tree";
import { requestWithToken } from "../api";

const asyncSelectOption = ({ store, labelKey, valueKey }) => {
  return store.results.map((instance) => ({
    label: instance[labelKey],
    value: instance[valueKey],
  }));
};

const asyncSelectSearch = ({ store, labelKey, valueKey, filterOptions }) => {
  return (input, callback) => {
    let filter = {};
    if (input) {
      filter.search = input;
    }
    if (filterOptions) {
      filter = { ...filter, ...filterOptions };
    }

    store.list({ ...filter }).then((response) => {
      const options = asyncSelectOption({
        store,
        labelKey,
        valueKey,
      });
      callback(options);
    });
  };
};

const selectSearch = ({ store, filterOptions, selected = null }) => {
  return {
    loadOptions: async (value, callback) => {
      await store.list({
        search: value,
        ...filterOptions,
      });
      let options = store.results.map((obj) => ({
        label: obj.display,
        value: obj.id,
      }));

      if (selected) {
        const response = await requestWithToken.get(
          `${store.endpoint}/${selected}`
        );
        const instance = getChildType(store.results).create({
          ...response.data,
        });
        options.push({
          label: instance.display,
          value: instance.id,
        });
      }
      callback(options);
    },
    isLoading: store.isLoading,
  };
};

const removeNullProperties = (obj) => {
  try {
    Object.keys(obj).forEach((key) => {
      let value = obj[key];
      let hasProperties = value && Object.keys(value).length > 0;
      if (value === null) {
        delete obj[key];
      } else if (typeof value !== "string" && hasProperties) {
        removeNullProperties(value);
      }
    });
  } catch (err) {
    console.error(err);
  }
  return obj;
};

const getModelDetails = (model, targetFields = []) => {
  if (!model) {
    return null;
  }

  let fields = Object.entries(getMembers(model).properties);

  if (targetFields.length > 0) {
    fields = fields.filter((field) => targetFields.includes(field[0]));
  }

  return fields.map((field) => {
    const pattern = /((\w+)|(\w+))/g;
    const matches = field[1].name.match(pattern);
    return {
      name: field[0],
      type: matches[0],
      default: matches[1],
      value: model[field[0]],
    };
  });
};

const setLocalStorageWithExpiry = ({ key, value, ttl }) => {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

const getLocalStorageWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

const getLocalDateToday = () => {
  return moment.utc().local().format("YYYY-MM-DD");
};

const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

const camelCaseKeysToSnakeCase = (obj) => {
  if (typeof obj != "object") return obj;
  let newObj = { ...obj };
  for (var oldName in newObj) {
    // Camel to underscore
    let newName = camelToSnakeCase(oldName);

    // Only process if names are different
    if (newName !== oldName) {
      // Check for the old property name to avoid a ReferenceError in strict mode.
      if (newObj.hasOwnProperty(oldName)) {
        newObj[newName] = newObj[oldName];
        delete newObj[oldName];
      }
    }

    // Recursion
    if (typeof newObj[newName] == "object") {
      newObj[newName] = camelCaseKeysToSnakeCase(newObj[newName]);
    }
  }
  return newObj;
};

const objToQueryString = (obj) => {
  let str = [];
  for (let p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};

export {
  asyncSelectSearch,
  asyncSelectOption,
  setLocalStorageWithExpiry,
  getLocalStorageWithExpiry,
  getModelDetails,
  getLocalDateToday,
  removeNullProperties,
  camelToSnakeCase,
  objToQueryString,
  camelCaseKeysToSnakeCase,
  selectSearch,
};
