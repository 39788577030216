import React, { Component } from "react";

import moment from "moment";
import { observer } from "mobx-react";

import Skeleton from "react-loading-skeleton";

@observer
class DisplayValue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      value: null,
      isLoading: false,
    };
  }

  formatDate = (dateToFormat) => {
    if (dateToFormat === null || dateToFormat === undefined) {
      return "-";
    }
    return moment(new Date(dateToFormat)).format("LLL");
  };

  async componentDidMount() {
    await this.updateValue();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      await this.updateValue();
    }
  }

  updateValue = async () => {
    const { type, value } = this.props;
    // gracefully check if the value is a promise from a reference field.
    this.setState({ isLoading: true });
    let displayValue = value;

    try {
      displayValue = await Promise.resolve(value);
    } catch {
      displayValue = "";
    }

    if (type === "DateTime") {
      displayValue = this.formatDate(displayValue);
    } else if (type === "boolean") {
      displayValue = displayValue ? "✔" : "✘";
    } else if (type === "reference") {
      displayValue = displayValue?.display;
    } else if (displayValue?.hasOwnProperty("display")) {
      displayValue = displayValue.display;
    }
    displayValue =
      displayValue === null ||
      typeof displayValue === "undefined" ||
      displayValue === ""
        ? "- - - -"
        : String(displayValue);
    this.setState({ type, value: displayValue, isLoading: false });
  };

  render() {
    const { value, isLoading } = this.state;
    return (
      <span className="value">
        {isLoading ? <Skeleton height={24} /> : value}
      </span>
    );
  }
}

export default DisplayValue;
