import React from "react";
import ReactDOM from "react-dom";

// Bootstrap Stylesheet
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";

// Other Libraries
import "jquery";
import "popper.js";
import $ from "jquery";
// Alertify
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/bootstrap.css";

import "react-loading-skeleton/dist/skeleton.css";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
// Animation Stylesheet
import "./assets/css/animate.css";

// Custom Stylesheet
import "./assets/css/style.min.css";

// import * as serviceWorker from './serviceWorker';

import App from "./scenes/app";

if (!process.env.REACT_APP_DEBUG) {
  Sentry.init({
    dsn: "https://3245d971b608407b898c8f0a5b88ec37@o955686.ingest.sentry.io/5952293",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

window.jQuery = $;
window.$ = $;
global.jQuery = $;

function prepare() {
  if (process.env.NODE_ENV === "development_mocked") {
    const { worker } = require("./mocks/browser");
    return worker.start();
  }

  return Promise.resolve();
}

prepare().then(() => {
  ReactDOM.render(<App />, document.getElementById("app"));
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// serviceWorker.unregister();
