import { types } from "mobx-state-tree";

import { requestWithToken } from "../../api";

import { BaseModel, Pagination } from ".";

import { MasterCifByIdReference } from "./master-cif";

import { CreateModelMixin, RetrieveModelMixin } from "./mixins";

const GroupFields = types
  .compose(
    CreateModelMixin,
    RetrieveModelMixin,
    types.model({
      name: types.string,
      masterCif: types.union(MasterCifByIdReference, types.integer, types.null),
      groupId: types.maybeNull(types.string),
    })
  )
  .views((self) => ({
    get display() {
      return self.name;
    },
  }))
  .volatile((self) => ({
    endpoint: "groups",
    hiddenFields: ["groupId"],
  }));

const Group = types.compose(GroupFields, BaseModel);

const Groups = types
  .compose(
    Pagination,
    types.model({
      results: types.array(Group),
    })
  )
  .volatile((self) => ({
    endpoint: "groups",
  }));

const GroupByIdReference = types.reference(Group, {
  async get(value) {
    const response = await requestWithToken.get(`groups/${value}/`);
    let group = Group.create({
      ...response.data,
    });
    return group;
  },
  set(value) {
    return value;
  },
});

export { GroupFields, Group, Groups, GroupByIdReference };
