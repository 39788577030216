import React, { Component } from "react";
import { Link } from "react-router-dom";

import alertify from "alertifyjs";
import _ from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import Logo from "../../components/logo";

import { activation } from "../../api/account";

export default class UserActivationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      uid: "",
      activated: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    const url = window.location.href;
    const urlParams = new URLSearchParams(url.split("?")[1]);

    const uid = urlParams.get("uid");
    const token = urlParams.get("token");

    this.setState({ uid, token });
  }

  handleChange = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    const { uid, token } = this.state;
    this.setState({
      isLoading: true,
    });
    activation(
      {
        uid,
        token,
      },
      (response) => {
        if (response.status >= 200 && response.status < 300) {
          alertify.success(
            "Your account has been successfully created and activated!"
          );
          this.setState({ activated: true });
        } else if (response.status >= 400 && response.status < 500) {
          alertify.error(
            Object.entries(response.data)
              .map(([key, value]) => {
                return `${_.startCase(key).toUpperCase()} - ${
                  Array.isArray(value) ? value.join() : value
                }`;
              })
              .join()
          );
        }
        this.setState({
          isLoading: false,
        });
      }
    );
    return false;
  };

  render() {
    const { isLoading, activated } = this.state;
    return (
      <div className="login-container box-shadow--dark">
        <div className="login-header">
          <div className="d-inline-block login-logo">
            <Logo width="40" height="47" />
          </div>
          <div className="d-inline-block login-title">
            <span>CashMob</span>
            <span className="subtitle">P O R T A L</span>
          </div>
        </div>
        <div className="login-body">
          {activated ? (
            <div>
              <div>
                Your account has been successfully created and activated!
              </div>
              <Link to="/login">
                <button
                  type="button"
                  className="btn btn-link primary-color-link"
                >
                  Login
                </button>
              </Link>
            </div>
          ) : (
            <form onSubmit={this.handleSubmit}>
              <div>Click on the following link to activate your account</div>
              <br />
              <button
                type="submit"
                className="primary-color-btn login-submit-button"
                disabled={isLoading}
              >
                {isLoading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  "Activate your account here"
                )}
              </button>
            </form>
          )}
        </div>
      </div>
    );
  }
}
