import { types } from "mobx-state-tree";

import { requestWithToken } from "../../api";

import { Pagination } from ".";

import { CashUnitByIdReference } from "./cash-unit";

import {
  CreateModelMixin,
  RetrieveModelMixin,
  UpdateModelMixin,
  DestroyModelMixin,
} from "./mixins";

const CashUnitConfigurationFields = types
  .compose(
    CreateModelMixin,
    RetrieveModelMixin,
    UpdateModelMixin,
    DestroyModelMixin,
    types.model({
      id: types.maybeNull(types.integer),
      cashUnit: types.union(CashUnitByIdReference, types.integer, types.null),
      tellerRafflingLimit: types.integer,
      isGeofencingActive: types.boolean,
    })
  )
  .views((self) => ({
    get display() {
      return `Cash Unit Identifier: ${self.cashUnit.display} Raffling Limit: ${self.teller_raffling_limit} Has Geofencing: ${self.is_geofencing_active}`;
    },
  }))
  .volatile((self) => ({
    endpoint: "cash-unit-configurations",
    hiddenFields: ["cashUnit", "id"],
    patches: {},
  }));

const CashUnitConfiguration = CashUnitConfigurationFields;

const CashUnitConfigurations = types
  .compose(
    Pagination,
    types.model({
      results: types.array(CashUnitConfiguration),
    })
  )
  .volatile((self) => ({
    endpoint: "cash-unit-configurations",
  }));

export {
  CashUnitConfigurationFields,
  CashUnitConfiguration,
  CashUnitConfigurations,
};
